import { useQuery } from '@tanstack/react-query';
import { Action } from 'features/cash4/shared/components/T4ActionMenu';
import T4Drawer from 'features/cash4/shared/components/T4SideDrawer/T4DrawerShell';
import { TransactionDrawer } from 'features/cash4/transactions/components/TransactionDrawer/TransactionDrawer';
import { EditRuleProvider } from 'features/cash4/transactions/providers/useEditRule';
import { fetchTransaction } from 'features/cash4/transactions/services';
import { observer } from 'mobx-react-lite';
import { FC } from 'react';
import { useClients } from 'shared/hooks/useClients';

export type C4TransactionsDrawerProps = {
	isOpen: boolean;
	transactionId?: string;
	disableLink?: boolean;
	onClose: () => void;
	transactionActions?: Action[];
};

export const C4TransactionsDrawer: FC<C4TransactionsDrawerProps> = observer(
	({ isOpen, onClose, disableLink, transactionActions, transactionId }) => {
		const { customerApiClient } = useClients();
		const { data, isLoading } = useQuery(
			['transaction', transactionId],
			() => fetchTransaction(transactionId!, customerApiClient),
			{
				refetchOnWindowFocus: false,
			},
		);

		return (
			<EditRuleProvider>
				<T4Drawer
					open={isOpen}
					onClose={onClose}
					stonlyPrefix="transaction-details"
				>
					<TransactionDrawer
						loading={isLoading}
						transaction={data!}
						disableLink={disableLink}
						transactionActions={transactionActions}
					/>
				</T4Drawer>
			</EditRuleProvider>
		);
	},
);
