import { Cancel, CheckCircle, Share } from '@mui/icons-material';
import { Divider, Drawer, Grid, Tooltip, Typography } from '@mui/material';
import { QueryObserverResult } from '@tanstack/react-query';
import { T4Button } from 'features/entity4/shared/components/atoms/t4Button';
import { T4TextFieldV2 } from 'features/entity4/shared/components/atoms/t4TextField';
import { T4AlertStack } from 'features/entity4/shared/components/molecules/t4AlertStack';
import {
	Payment,
	PaymentStatusTypes,
} from 'modules/clients/apiGateway/payments4/payments';
import { T4ProblemDetails } from 'modules/clients/types';
import { useSnackbar } from 'notistack';
import { FC, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { CopyToClipboardIconButton } from 'shared/components/copyToClipboardIconButton';
import { DrawerWidth, T4DrawerBase } from 'shared/components/drawer/drawerBase';
import { DrawerCancelButton } from 'shared/components/drawer/drawerButtons';
import { FormModal } from 'shared/components/formModal';
import { useClients } from 'shared/hooks/useClients';
import { useUser } from 'shared/hooks/useUser';
import { convertDate, formatReadDate } from 'shared/utilities/dateUtilities';
import { stonlyData } from 'stonly/functions';
import { formatCurrency, getCurrencySymbol } from 'utilities/currencyUtils';
import { flattenProblemDetails } from 'utilities/errors/errorUtils';
import { isStringUndefinedOrNullOrWhitespace } from 'utilities/stringUtils';
import { useGetPayment } from '../hooks/usePayments';
import { useGetAllPaymentTemplates } from '../hooks/usePaymentTemplates';
import { PaymentPartyInformation } from '../paymentPartyInformationBox';
import { getApprovalStatusText } from '../utilities';

const stonlyIds = {
	cancelButton: 'review-payment-drawer-cancel-button',
	rejectButton: 'review-payment-drawer-reject-button',
	approveButton: 'review-payment-drawer-approve-button',
};

const gridRowSx = {
	display: 'flex',
	flexDirection: 'row',
	justifyContent: 'space-between',
};
const typographySx = {
	display: 'inline',
};

interface ReviewPaymentDrawerProps {
	onClose: () => void;
	paymentId: string | null;
	refetch: () => Promise<QueryObserverResult<Payment[], Error>>;
}

export const ReviewPaymentDrawer: FC<ReviewPaymentDrawerProps> = ({
	onClose,
	paymentId,
	refetch,
}) => {
	// #region State

	const { user } = useUser();
	const { applicationApiClient } = useClients();
	const { enqueueSnackbar } = useSnackbar();

	const {
		isLoading: isPaymentLoading,
		isFetching: isPaymentFetching,
		data: payment,
		error: paymentError,
	} = useGetPayment(paymentId);
	useEffect(() => {
		if (!isPaymentLoading && paymentError?.message) {
			enqueueSnackbar(paymentError.message, {
				variant: 'error',
			});
			onClose();
		}
	}, [isPaymentLoading, paymentError, enqueueSnackbar, onClose]);

	const [isLoading, setIsLoading] = useState<boolean>(false);
	const [errors, setErrors] = useState<string[]>([]);
	const errorsRef = useRef<HTMLDivElement>(null);

	const [isRejectionDialogOpen, setIsRejectionDialogOpen] =
		useState<boolean>(false);
	const [rejectionReason, setRejectionReason] = useState<string | null>(null);

	const [isFinalApprovalLevelModalOpen, setIsFinalApprovalLevelModalOpen] =
		useState<boolean>(false);

	const {
		isLoading: areTemplatesLoading,
		isFetching: areTemplatesFetching,
		data: templates,
	} = useGetAllPaymentTemplates(!!paymentId);

	const resetDrawer = useCallback(() => {
		setIsLoading(false);
		setErrors([]);
		setIsRejectionDialogOpen(false);
		setRejectionReason(null);
	}, []);

	// #endregion

	// #region Submit Functions

	const onApprove = useCallback(async () => {
		try {
			if (!payment) return;
			setErrors([]);
			setIsLoading(true);

			const response = await applicationApiClient.payments4.payments.approve(
				payment.id,
			);

			if (response.status === 200) {
				onClose();
				resetDrawer();
				refetch();
				enqueueSnackbar('Successfully approved payment.', {
					variant: 'success',
				});
			} else if (response.status === 400 && response.data) {
				setErrors(flattenProblemDetails(response.data as T4ProblemDetails));
				errorsRef?.current?.scrollIntoView({
					behavior: 'smooth',
					block: 'start',
				});
			} else throw new Error();
		} catch (error: any) {
			enqueueSnackbar(
				'An unexpected error occured and we were unable to approve the payment. Please try again later.',
				{
					variant: 'error',
				},
			);
		} finally {
			setIsLoading(false);
		}
	}, [
		payment,
		applicationApiClient,
		errorsRef,
		onClose,
		resetDrawer,
		refetch,
		enqueueSnackbar,
	]);

	const onReject = useCallback(async () => {
		try {
			if (!payment) return;
			setErrors([]);
			setIsLoading(true);

			const response = await applicationApiClient.payments4.payments.reject({
				id: payment.id,
				data: {
					reason: rejectionReason,
				},
			});

			if (response.status === 200) {
				onClose();
				resetDrawer();
				refetch();
				enqueueSnackbar('Successfully rejected payment.', {
					variant: 'success',
				});
			} else if (response.status === 400 && response.data) {
				setErrors(flattenProblemDetails(response.data as T4ProblemDetails));
				errorsRef?.current?.scrollIntoView({
					behavior: 'smooth',
					block: 'start',
				});
			} else throw new Error();
		} catch (error: any) {
			enqueueSnackbar(
				'An unexpected error occured and we were unable to reject the payment. Please try again later.',
				{
					variant: 'error',
				},
			);
		} finally {
			setIsLoading(false);
		}
	}, [
		payment,
		rejectionReason,
		applicationApiClient,
		errorsRef,
		onClose,
		resetDrawer,
		refetch,
		enqueueSnackbar,
	]);

	// #endregion

	// #region Memoized Values

	const paymentTemplate = useMemo(
		() => (templates ?? []).find((x) => x.id === payment?.paymentTemplateId),
		[payment, templates],
	);

	const currentApprovalLevel = useMemo(
		() =>
			payment?.approvalState?.paymentApprovalLevelStates.find(
				(levelState) =>
					levelState.paymentApprovalLevelId ===
					payment.approvalState!.currentApprovalLevelId,
			),
		[payment],
	);

	const isFinalApprovalLevel = useMemo(() => {
		if (!payment || payment.approvalState === null) return false;
		const currentLevelIndex =
			payment?.approvalState?.paymentApprovalLevelStates.findIndex(
				(levelState) =>
					levelState.paymentApprovalLevelId ===
					payment.approvalState?.currentApprovalLevelId,
			);

		return (
			currentLevelIndex !== -1 &&
			currentLevelIndex ===
				payment.approvalState.paymentApprovalLevelStates.length - 1
		);
	}, [payment]);

	const userCanApprove = useMemo(() => {
		if (!payment?.approvalState || payment.approvalState === null)
			return {
				canApprove: false,
				tooltipMessage:
					'Payment did not match an approval rule and/or tier. Please try re-submitting the payment.',
			};

		// shouldn't run into this, but it will protect an edge case
		if (payment.approvalState.isCompleted)
			return {
				canApprove: false,
				tooltipMessage: 'Payment approval process is already complete.',
			};

		if (
			!payment.approvalState.canApproveOwnPayments &&
			payment.approvalState.paymentCreatedBy?.userId === user.sub
		)
			return {
				canApprove: false,
				tooltipMessage: 'You cannot approve a payment you created.',
			};

		// another edge case protection
		if (!currentApprovalLevel)
			return {
				canApprove: false,
				tooltipMessage:
					'An unexpected error occured in the approval process. Please contact a system admin for assistance.',
			};

		if (
			!payment.approvalState.hasSequentialApprovers &&
			payment.approvalState.paymentApprovalLevelStates.some(
				(levelState) =>
					levelState.level < currentApprovalLevel.level &&
					levelState.approvedBy?.userId === user.sub,
			)
		)
			return {
				canApprove: false,
				tooltipMessage:
					'You have already approved this payment at a previous level.',
			};

		if (
			!currentApprovalLevel.approvers?.some(
				(approver) => approver.userId === user.sub,
			)
		)
			return {
				canApprove: false,
				tooltipMessage:
					'You do not have approval rights for this payment at the current level.',
			};

		return {
			canApprove: true,
			tooltipMessage: '',
		};
	}, [payment, currentApprovalLevel, user]);

	const userCanReject = useMemo(() => {
		// if payment has no approval state and the user rejecting was the one who originally submitted the payment, then let them reject
		if (
			payment &&
			payment.approvalState === null &&
			payment.statusHistory.some(
				(status) =>
					status.paymentStatusType ===
						PaymentStatusTypes[PaymentStatusTypes.Submitted] &&
					status.createdBy?.userId === user.sub,
			)
		)
			return {
				canReject: true,
				tooltipMessage: '',
			};

		if (!payment?.approvalState || payment.approvalState === null)
			return {
				canReject: false,
				tooltipMessage:
					'Payment did not match an approval rule and/or tier. Please try re-submitting the payment.',
			};

		// shouldn't run into this, but it will protect an edge case
		if (payment.approvalState.isCompleted)
			return {
				canReject: false,
				tooltipMessage: 'Payment approval process is already complete.',
			};

		// another edge case protection
		if (!currentApprovalLevel)
			return {
				canReject: false,
				tooltipMessage:
					'An unexpected error occured in the approval process. Please contact a system admin for assistance.',
			};

		if (
			!payment.approvalState.hasSequentialApprovers &&
			payment.approvalState.paymentApprovalLevelStates.some(
				(levelState) =>
					levelState.level < currentApprovalLevel.level &&
					levelState.approvedBy?.userId === user.sub,
			)
		)
			return {
				canReject: false,
				tooltipMessage:
					'You have already approved this payment at a previous level.',
			};

		if (
			!currentApprovalLevel.approvers?.some(
				(approver) => approver.userId === user.sub,
			)
		)
			return {
				canReject: false,
				tooltipMessage:
					'You do not have approval rights for this payment at the current level.',
			};

		return {
			canReject: true,
			tooltipMessage: '',
		};
	}, [payment, currentApprovalLevel, user]);

	const submitActions = useMemo(() => {
		return [
			<DrawerCancelButton
				stonlyId={stonlyIds.cancelButton}
				onCancel={() => {
					onClose();
					resetDrawer();
				}}
				label="Close"
			/>,
			<Tooltip title={userCanReject.tooltipMessage}>
				<span>
					<T4Button
						{...stonlyData({ id: stonlyIds.rejectButton })}
						color="error"
						variant="contained"
						onClick={() => setIsRejectionDialogOpen(true)}
						startIcon={<Cancel />}
						disabled={isLoading || !userCanReject.canReject}
					>
						Reject
					</T4Button>
				</span>
			</Tooltip>,
			<Tooltip title={userCanApprove.tooltipMessage}>
				<span>
					<T4Button
						{...stonlyData({ id: stonlyIds.approveButton })}
						color="primary"
						variant="contained"
						onClick={async () => {
							if (isFinalApprovalLevel) {
								setIsFinalApprovalLevelModalOpen(true);
							} else await onApprove();
						}}
						disabled={isLoading || !userCanApprove.canApprove}
						startIcon={<CheckCircle />}
					>
						Approve
					</T4Button>
				</span>
			</Tooltip>,
		];
	}, [
		isLoading,
		userCanApprove,
		userCanReject,
		isFinalApprovalLevel,
		onApprove,
		onClose,
		resetDrawer,
	]);

	const RejectionDrawer = useMemo(
		() => (
			<Drawer
				variant="temporary"
				anchor="right"
				open={isRejectionDialogOpen}
				onClose={() => setIsRejectionDialogOpen(false)}
				sx={{
					'& .MuiPaper-root': {
						top: 'auto',
						bottom: 0,
						width: DrawerWidth,
						height: 'fit-content',
					},
				}}
			>
				<Grid
					container
					sx={{ gap: 2, paddingX: '1.5rem', paddingY: '0.75rem' }}
				>
					<Grid item xs={12}>
						<Typography variant="h4" fontWeight={500}>
							Reason For Rejection
						</Typography>
					</Grid>
					<Grid item xs={12}>
						<T4TextFieldV2
							id="rejection-reason"
							label="What needs to be changed or fixed?"
							value={rejectionReason ?? ''}
							onChange={(value: string) => {
								if (isStringUndefinedOrNullOrWhitespace(value))
									setRejectionReason(null);
								else setRejectionReason(value);
							}}
							multiline
							minRows={4}
							maxRows={4}
							helperText={`${rejectionReason?.length ?? 0}/200`}
							inputProps={{ maxLength: 200 }}
						/>
					</Grid>
					<Grid
						container
						item
						xs={12}
						sx={{ justifyContent: 'flex-end' }}
						columnSpacing={1}
					>
						<Grid item xs="auto">
							<DrawerCancelButton
								onCancel={() => setIsRejectionDialogOpen(false)}
								stonlyId="reject-payment-cancel-button"
							/>
						</Grid>
						<Grid item xs="auto">
							<T4Button
								color="error"
								variant="contained"
								onClick={() => {
									setIsRejectionDialogOpen(false);
									onReject();
								}}
								disabled={isLoading}
							>
								Reject
							</T4Button>
						</Grid>
					</Grid>
				</Grid>
			</Drawer>
		),
		[isRejectionDialogOpen, rejectionReason, isLoading, onReject],
	);

	const FinalApprovalLevelModal = useMemo(
		() =>
			payment !== undefined ? (
				<FormModal
					title="Send Payment Confirmation"
					description={`You are the final approver for this payment. By clicking 'Confirm,' you acknowledge that you are authorizing the release of ${formatCurrency(
						payment.instructedAmount.value,
						{
							currency: payment.instructedAmount.currencyCode ?? undefined,
						},
					)} to ${payment.payee.displayName}.`}
					open={isFinalApprovalLevelModalOpen}
					loading={isLoading}
					onClose={() => setIsFinalApprovalLevelModalOpen(false)}
					onSubmit={() => {
						onApprove();
						setIsFinalApprovalLevelModalOpen(false);
					}}
					submitDisabled={isLoading}
					submitButtonLabel="Confirm"
				/>
			) : null,
		[payment, isFinalApprovalLevelModalOpen, isLoading, onApprove],
	);

	// #endregion

	return (
		<T4DrawerBase
			title="Review Payment"
			open={!!paymentId}
			initializing={
				isPaymentLoading ||
				isPaymentFetching ||
				areTemplatesLoading ||
				areTemplatesFetching
			}
			loading={isLoading}
			onClose={() => {
				onClose();
				resetDrawer();
			}}
			utilityActions={[
				<CopyToClipboardIconButton
					valueToCopy={window.location.href}
					initialTooltipText="Copy Link"
				>
					<Share />
				</CopyToClipboardIconButton>,
			]}
			actions={submitActions}
			disableNavigationCollapse
		>
			<Grid container sx={{ gap: 2 }}>
				<Grid container item xs={12} spacing={1}>
					{paymentTemplate !== undefined && (
						<Grid item xs={12} sx={gridRowSx}>
							<Typography
								variant="body1"
								sx={{ fontWeight: 500, ...typographySx }}
							>
								Template Name
							</Typography>
							<Typography variant="body1" sx={typographySx}>
								{paymentTemplate.name}
							</Typography>
						</Grid>
					)}
					<Grid item xs={12} sx={gridRowSx}>
						<Typography
							variant="body1"
							sx={{ fontWeight: 500, ...typographySx }}
						>
							Payment Type
						</Typography>
						<Typography variant="body1" sx={typographySx}>
							{payment?.paymentType}
						</Typography>
					</Grid>
					<Grid item xs={12} sx={gridRowSx}>
						<Typography
							variant="body1"
							sx={{ fontWeight: 500, ...typographySx }}
						>
							Value Date
						</Typography>
						<Typography variant="body1" sx={typographySx}>
							{formatReadDate(convertDate(payment?.valueDate))}
						</Typography>
					</Grid>
					<Grid item xs={12} sx={gridRowSx}>
						<Typography
							variant="body1"
							sx={{ fontWeight: 500, ...typographySx }}
						>
							Payment Value
						</Typography>
						<Typography variant="body1" sx={typographySx}>
							{`${getCurrencySymbol(
								'en-US',
								payment?.instructedAmount.currencyCode,
							)}${payment?.instructedAmount.value?.toFixed(2)}`}
						</Typography>
					</Grid>
					<Grid item xs={12} sx={gridRowSx}>
						<Typography
							variant="body1"
							sx={{ fontWeight: 500, ...typographySx }}
						>
							Payment Currency
						</Typography>
						<Typography variant="body1" sx={typographySx}>
							{payment?.instructedAmount.currencyCode}
						</Typography>
					</Grid>
					<Grid item xs={12} sx={gridRowSx}>
						<Typography
							variant="body1"
							sx={{ fontWeight: 500, ...typographySx }}
						>
							Current Approval Status
						</Typography>
						<Typography variant="body1" sx={typographySx}>
							{getApprovalStatusText(payment)}
						</Typography>
					</Grid>
				</Grid>

				<Grid item xs={12}>
					<Divider />
				</Grid>

				<Grid container item xs={12} spacing={1}>
					<PaymentPartyInformation
						party={payment?.initiator ?? null}
						partyType="Initiator"
					/>
				</Grid>

				<Grid item xs={12}>
					<Divider />
				</Grid>

				<Grid container item xs={12} spacing={1}>
					<PaymentPartyInformation
						party={payment?.payee ?? null}
						partyType="Payee"
					/>
				</Grid>

				<Grid item xs={12}>
					<T4TextFieldV2
						label="Payment Information"
						value={payment?.referenceData ?? ''}
						minRows={4}
						maxRows={4}
						multiline
						InputProps={{ readOnly: true }}
					/>
				</Grid>

				{Object.values(errors).length > 0 && (
					<Grid item xs={12} ref={errorsRef}>
						<T4AlertStack errors={errors} />
					</Grid>
				)}
			</Grid>

			{RejectionDrawer}
			{FinalApprovalLevelModal}
		</T4DrawerBase>
	);
};
