import { Add, Balance, CurrencyExchange, SwapHoriz } from '@mui/icons-material';
import { TabContext, TabList, TabPanel } from '@mui/lab';
import {
	Button,
	Grid,
	Link,
	MenuItem,
	Tab,
	Tooltip,
	Typography,
} from '@mui/material';
import {
	GridColDef,
	GridRowClassNameParams,
	GridValueGetterParams,
} from '@mui/x-data-grid-pro';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { T4Button } from 'features/entity4/shared/components/atoms/t4Button';
import { T4TextFieldV2 } from 'features/entity4/shared/components/atoms/t4TextField';
import { LegalEntityGroupsFilter } from 'features/entity4/shared/components/legalEntityGroupsFilter';
import { T4AlertStack } from 'features/entity4/shared/components/molecules/t4AlertStack';
import { observer } from 'mobx-react-lite';
import { ProjectedItem } from 'modules/clients/customer-api/src/api/cash4';
import moment, { Moment } from 'moment';
import { enqueueSnackbar } from 'notistack';
import { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { ActuallyPrettyGoodDataGridWrapper } from 'shared/components/actuallyPrettyGoodDataGridWrapper';
import {
	ConfirmationDialog,
	IConfirmationDialogProps,
} from 'shared/components/confirmationDialog';
import { NoRowsOverlay } from 'shared/components/dataGrid/noRowsOverlay';
import { UserPreferencesDataGrid } from 'shared/components/dataGrid/userPreferencesDataGrid';
import T4DateRangePicker from 'shared/components/dateRangePicker/t4DateRangePicker';
import { PageHeader, pageHeaderStonlyIds } from 'shared/components/pageHeader';
import { T4View } from 'shared/components/t4View';
import { paths } from 'shared/constants/paths';
import { useSessionStorage } from 'shared/hooks/useSessionStorage';
import { useT4FeatureFlags } from 'shared/hooks/useT4FeatureFlags';
import { useUser } from 'shared/hooks/useUser';
import { useLegalEntityGroups } from 'shared/providers/legalEntityGroupsProvider';
import { brandColors } from 'shared/theme/brandColors';
import {
	getCurrencyColumnDefinition,
	getDateColumnDefinition,
	getFormattedDateTimeColumnDefinition,
} from 'shared/utilities/dataGrid/columnDefinitions';
import { DataGridColumnWidths } from 'shared/utilities/dataGrid/dataGridUtils';
import { getQueryParam } from 'shared/utilities/navigationUtilities';
import { stonlyData } from 'stonly/functions';
import { formatCurrency } from 'utilities/currencyUtils';
import { useProjectedItemsQuery } from '../_queries/useProjectedItemsQuery';
import { C4ProjectedEditModal } from '../_shared/_components/c4ProjectedEditModal';
import { useAccountIntegrationsContext } from '../accountIntegrations/providers/accountIntegrationsProviders';
import { C4TransactionsDrawer } from '../reconciliations/_components/c4TransactionsDrawer';
import { ReconciliationDrawer } from '../reconciliations/_components/reconciliationDrawer';
import { useReconciliationsContext } from '../reconciliations/_providers/reconciliationsProvider';
import T4Drawer from '../shared/components/T4SideDrawer/T4DrawerShell';
import {
	Action,
	Actions,
	ActionsEnum,
} from '../shared/components/T4ActionMenu';
import { ProjectedItemDrawer } from './components/ProjectedItems/projectedItemDrawer';
import { ProjectionDrawer } from './components/ProjectionDrawer/ProjectionDrawer';
import {
	CurrencyOption,
	TransactionListItem,
	TransactionValue,
} from './models';
import { useTransactions } from './providers/useTransactions';
import { deleteProjectedTransactionApiCall } from './services';
import { stonlyIds as stonly } from './utilities/index';
import { DateRange } from '@mui/x-date-pickers-pro';
import {
	normalizeReconciliationStatus,
	ReconciliationStatus,
} from '../reconciliations/_hooks/useReconciliationCalculations';
export const dataTestIds = {
	categorizationInfoModal: 'categorization-info-modal',
	createProjectedItemButton: 'create-projected-item-button',
};

export const stonlyIds = {
	categorizationInfoModal: 'categorization-info-modal',

	transactionsDrawer: 'cash4-transactions-drawer',
	transactionsRowContextMenu: 'cash4-transactions-row-context-menu',
	transactionsRowContextMenuView: 'cash4-transactions-row-context-menu-view',
	transactionsRowContextMenuDelete:
		'cash4-transactions-row-context-menu-delete',
	transactionsDeleteConfirmationModal:
		'cash4-transactions-delete-confirmation-modal',
	transactionsDeleteConfirmationCancelButton:
		'cash4-transactions-delete-confirmation-cancel',
	transactionsDeleteConfirmationDeleteButton:
		'cash4-transactions-delete-confirmation-delete',

	// Projected Transactions
	projectedTransactionsGrid: 'cash4-projected-transactions-grid',
	projectedTransactionsDrawer: 'cash4-projected-transactions-drawer',
	projectedTransactionsRowContextMenu:
		'cash4-projected-transactions-row-context-menu',
	projectedTransactionsRowContextMenuView:
		'cash4-projected-transactions-row-context-menu-view',
	projectedTransactionsRowContextMenuDelete:
		'cash4-projected-transactions-row-context-menu-delete',
	projectedTransactionsDeleteConfirmationModal:
		'cash4-projected-transactions-delete-confirmation-modal',
	projectedTransactionsDeleteConfirmationCancelButton:
		'cash4-projected-transactions-delete-confirmation-cancel',
	projectedTransactionsDeleteConfirmationDeleteButton:
		'cash4-projected-transactions-delete-confirmation-delete',
	projectedTransactionsCreateButton:
		'cash4-transactions-create-projected-item-button',
};

export type TransactionPageTab = 'reported' | 'projected';

export const TransactionsPage: FC = observer(() => {
	const { projectedTransactionsEnabled } = useT4FeatureFlags();
	const user = useUser();
	const { legalEntityGroupIds } = useLegalEntityGroups();
	const history = useHistory();
	const queryClient = useQueryClient();
	const { transactionId, projectionId } = useParams<{
		transactionId: string;
		projectionId: string;
	}>();
	const {
		dateRange,
		handleDateRangeChange,
		defaultDateRange,
		fetchTransactions,
		currencyOption,
		configurations,
		handleCurrencyOptionChange,
		deleteCashTransactionApiCall,
	} = useTransactions();
	const { setTransactionStartDate, setTransactionEndDate } =
		useSessionStorage();
	const {
		startReconciliationCreation,
		viewReconciliation,
		reconciliationQueryContext: { refetch: refetchReconciliations },
	} = useReconciliationsContext();

	const {
		updateCurrentDay,
		getCurrentDayRefreshStatus,
		getCurrentDayRefreshDate,
	} = useAccountIntegrationsContext();

	const {
		projectedItemStartDate,
		projectedItemEndDate,
		setProjectedItemStartDate,
		setProjectedItemEndDate,
	} = useSessionStorage();

	const [projectedItemDateRange, setProjectedItemDateRange] = useState<
		DateRange<Moment>
	>([projectedItemStartDate, projectedItemEndDate]);

	const handleProjectedItemDateRangeChange = useCallback(
		(dateRange: DateRange<Moment>) => {
			setProjectedItemDateRange(dateRange);

			// Update session variables
			if (dateRange[0]) {
				setProjectedItemStartDate(dateRange[0]);
			}
			if (dateRange[1]) {
				setProjectedItemEndDate(dateRange[1]);
			}
		},
		[],
	);

	const {
		loading: projectedItemsLoading,
		data: projectedItems,
		refetch: refetchProjectedItems,
	} = useProjectedItemsQuery({
		startDate: projectedItemDateRange[0]?.format('YYYY-MM-DD'),
		endDate: projectedItemDateRange[1]?.format('YYYY-MM-DD'),
		legalEntityGroupIds,
	});

	const [projectedItem, setProjectedItem] = useState<ProjectedItem>();
	const [transaction, setTransaction] = useState<TransactionListItem>();
	const [openTransactionDrawer, setOpenTransactionDrawer] =
		useState<boolean>(false);
	const [confirmationModalLoading, setConfirmationModalLoading] =
		useState(false);
	const [openDialog, setOpenDialog] = useState(false);
	const [selectedDialog, setSelectedDialog] =
		useState<IConfirmationDialogProps>({} as IConfirmationDialogProps);
	const [openProjectionDrawer, setOpenProjectionDrawer] = useState(false);
	const [isEditWarningOpen, setIsEditWarningOpen] = useState(false);

	const [isCreateEditDrawerOpen, setIsCreatedEditDrawerOpen] =
		useState<boolean>(false);

	const handleCloseTransactionDrawer = () => {
		setOpenTransactionDrawer(false);
		history.push(paths.cash4.transactions.href);
	};

	const handleCloseProjectionDrawer = useCallback(() => {
		setOpenProjectionDrawer(false);
		history.push(`${paths.cash4.transactions.href}?tab=projected`);
	}, [history]);

	const handleOpenTransactionDrawer = () => {
		setOpenTransactionDrawer(true);
	};

	const handleOpenProjectionDrawer = useCallback(() => {
		setOpenProjectionDrawer(true);
	}, []);

	const handleOpenDialog = () => {
		setOpenDialog(true);
	};

	const handleCloseDialog = () => {
		setOpenDialog(false);
	};

	const handleCreateProjectedItemClick = useCallback(() => {
		setProjectedItem(undefined);
		setIsCreatedEditDrawerOpen(true);
	}, []);

	const handleProjectedViewClick = useCallback(
		(projection: ProjectedItem) => {
			setProjectedItem(projection);
			history.push(
				`${paths.cash4.projectedTransactions.href}/${
					projection.id
				}?tab=${'projected'}`,
			);
			handleOpenProjectionDrawer();
		},
		[handleOpenProjectionDrawer, history],
	);

	const handleProjectedEditClick = useCallback(
		(projection: ProjectedItem) => {
			setProjectedItem(projection);
			if (projection.reconciliationId) {
				setIsEditWarningOpen(true);
			} else {
				setIsCreatedEditDrawerOpen(true);
				setOpenProjectionDrawer(false);
			}
		},
		[setIsCreatedEditDrawerOpen, setIsEditWarningOpen],
	);

	const deleteTransaction = (data: { transactionId: string }) => {
		const { transactionId } = data;
		setConfirmationModalLoading(true);
		return deleteCashTransactionApiCall(transactionId);
	};

	const mutation = useMutation(deleteTransaction, {
		onSuccess: (data, variables) => {
			if (data) {
				handleCloseDialog();
				queryClient.invalidateQueries(['transactions']);
				enqueueSnackbar('Transaction deleted successfully.', {
					variant: 'success',
				});
			} else {
				enqueueSnackbar('Error deleting transaction.', {
					variant: 'error',
				});
			}
			setConfirmationModalLoading(false);
		},
	});

	const handleDeleteCashTransaction = useCallback(
		(transactionId: string) => {
			return mutation.mutate({ transactionId: transactionId });
		},
		[mutation],
	);

	const handleCashTransactionDeleteClick = useCallback(
		(transaction: TransactionListItem) => {
			setSelectedDialog({
				title: 'Delete Reported Transaction',
				text: 'Are you sure you want to delete this reported transaction? This action cannot be undone.',
				primaryButtonText: 'DELETE',
				onPrimaryButtonClick: () => {
					try {
						handleDeleteCashTransaction(transaction.id);
						setOpenTransactionDrawer(false);
					} catch {}
				},
				secondaryButtonText: 'CANCEL',
				onSecondaryButtonClick: handleCloseDialog,
			} as IConfirmationDialogProps);
			handleOpenDialog();
		},
		[handleDeleteCashTransaction],
	);

	const deleteProjectedTransaction = (data: {
		ProjectedTransactionId: string;
	}) => {
		const { ProjectedTransactionId } = data;
		return deleteProjectedTransactionApiCall(ProjectedTransactionId);
	};

	const projectedTransactionMutation = useMutation(deleteProjectedTransaction, {
		onSuccess: () => {
			refetchProjectedItems();
			enqueueSnackbar('The projected transaction was successfully deleted!', {
				variant: 'success',
			});
		},
		onError: () => {
			enqueueSnackbar(
				'An error occurred and the projected transaction could not be deleted.',
				{
					variant: 'error',
					autoHideDuration: 10000,
				},
			);
		},
	});

	const handleDeleteProjectedTransaction = useCallback(
		(projectedTransactionId: string) => {
			setConfirmationModalLoading(true);
			try {
				if (projectedTransactionId) {
					projectedTransactionMutation.mutate({
						ProjectedTransactionId: projectedTransactionId,
					});
				}
				handleCloseDialog();
				if (openProjectionDrawer) {
					handleCloseProjectionDrawer();
				}
			} catch {
			} finally {
				setConfirmationModalLoading(false);
			}
		},
		[
			handleCloseProjectionDrawer,
			openProjectionDrawer,
			projectedTransactionMutation,
		],
	);

	const handleProjectedTransactionDeleteClick = useCallback(
		(row: ProjectedItem) => {
			setSelectedDialog({
				title: 'Delete Projected Transaction?',
				text: 'This projected transaction will be permanently deleted from the system. This action cannot be undone.',
				primaryButtonText: 'DELETE',
				onPrimaryButtonClick: () => {
					handleDeleteProjectedTransaction(row.id);
				},
				secondaryButtonText: 'CANCEL',
				onSecondaryButtonClick: handleCloseDialog,
			} as IConfirmationDialogProps);

			handleOpenDialog();
		},
		[handleDeleteProjectedTransaction],
	);

	const createProjectedItemButton = useMemo(
		() => (
			<Button
				startIcon={<Add />}
				type="button"
				variant="outlined"
				color="primary"
				data-testid={dataTestIds.createProjectedItemButton}
				{...stonlyData({
					id: stonlyIds.projectedTransactionsCreateButton,
				})}
				onClick={() => handleCreateProjectedItemClick()}
			>
				Projection
			</Button>
		),
		[handleCreateProjectedItemClick],
	);

	const CreateEditDrawer = useMemo(
		() => (
			<ProjectedItemDrawer
				isOpen={isCreateEditDrawerOpen}
				projectedItem={projectedItem}
				onClose={() => {
					setIsCreatedEditDrawerOpen(false);
					setProjectedItem(undefined);
				}}
				onSubmit={() => {
					refetchProjectedItems();
					refetchReconciliations();
				}}
			/>
		),
		[
			isCreateEditDrawerOpen,
			projectedItem,
			refetchProjectedItems,
			refetchReconciliations,
		],
	);

	useEffect(() => {
		dateRange[0] && setTransactionStartDate(dateRange[0]);
		dateRange[1] && setTransactionEndDate(dateRange[1]);
	}, [dateRange, setTransactionEndDate, setTransactionStartDate]);

	const { data, isLoading, error } = useQuery(
		['transactions', dateRange, legalEntityGroupIds],
		() => fetchTransactions(dateRange, legalEntityGroupIds),
		{
			refetchOnWindowFocus: false,
		},
	);

	const { data: currentDayRefreshStatus } = useQuery(
		['currentDayRefreshStatus'],
		() => getCurrentDayRefreshStatus(),
		{
			refetchOnWindowFocus: false,
			initialData: false,
		},
	);

	const { data: lastRefreshedDate } = useQuery(
		['lastRefreshedDate'],
		() => getCurrentDayRefreshDate(),
		{
			refetchOnWindowFocus: false,
			initialData: '',
		},
	);

	useEffect(() => {
		if (transactionId) {
			handleOpenTransactionDrawer();
		}
	}, [transactionId]);

	useEffect(() => {
		if (projectionId) {
			handleOpenProjectionDrawer();
		}
	}, [handleOpenProjectionDrawer, projectionId]);

	const tabValue = useMemo(() => {
		return getQueryParam(history.location.search, 'tab') ?? 'reported';
	}, [history.location.search]);

	const handleTransactionViewClick = useCallback(
		(transaction: TransactionListItem) => {
			setTransaction(transaction);
			history.push(`${paths.cash4.transactions.href}/${transaction.id}`);
			handleOpenTransactionDrawer();
		},
		[history],
	);

	const refetchAllTransactions = () => {
		refetchProjectedItems();
		queryClient.invalidateQueries(['transactions']);
	};

	const columns = useMemo<GridColDef[]>(() => {
		const columns: GridColDef<TransactionListItem>[] = [
			{
				field: 'actions',
				headerName: '',
				width: 25,
				renderCell: (params) => {
					return (
						<Actions
							stonlyId={stonlyIds.projectedTransactionsRowContextMenu}
							actions={[
								{
									action: ActionsEnum.view,
									callback: () => handleTransactionViewClick(params.row),
								},
								...(user.cash4.isAuthor
									? [
											{
												action: ActionsEnum.delete,
												isDisabled: params.row.reconciliationRecordId
													? true
													: undefined,
												isDisabledTooltipText:
													'This record is associated with a reconciliation and cannot be deleted. Edit reconciliation to remove association.',
												callback: () =>
													handleCashTransactionDeleteClick(params.row),
											},
									  ]
									: []),
							]}
							id="transactionId-more-menu"
						/>
					);
				},
				resizable: false,
				sortable: false,
				disableColumnMenu: true,
				filterable: false,
				disableExport: true,
				hideable: false,
				disableReorder: true,
			},
			{
				...getDateColumnDefinition(),
				field: 'date',
				headerName: 'Date',
			},
			{
				field: 'e4AccountName',
				headerName: 'Account Name',
				headerClassName: 'wrap-column-header',
			},
			{
				field: 'e4AccountNumber',
				headerName: 'Account Number',
			},
			{
				field: 'c4AccountNumber',
				headerName: 'C4 Account Number',
			},
			{
				field: 'transactionCode',
				headerName: 'Bank Transaction Code',
			},
			{
				field: 'bankReference',
				headerName: 'Bank Reference',
			},
			{
				field: 'customerReference',
				headerName: 'Customer Reference',
			},
			{
				field: 'checkNumber',
				headerName: 'Check Number',
			},
			{
				field: 'detail',
				headerName: 'Transaction Details',
			},
			{
				field: 'noteContent',
				headerName: 'Notes',
			},
			{
				field: 'fiTransactionId',
				headerName: 'Bank Transaction Id',
			},
			{
				field: 'transactionRuleName',
				headerName: 'Rule Name',
				valueGetter: (params) => {
					return params.row.id === params.row.transactionRuleName
						? 'Manual Categorization'
						: params.row.transactionRuleName || 'No Rule Matched';
				},
			},
			{
				field: 'cfc',
				headerName: 'CFC',
				description: 'Cash Flow Class',
				width: DataGridColumnWidths.threeChar,
				renderCell: (value) => {
					return (
						<Tooltip title={value.row.cfcName}>
							<Typography variant="body2" noWrap>
								{value.row.cfc}
							</Typography>
						</Tooltip>
					);
				},
			},
			{
				field: 'cfcName',
				headerName: 'CFC Description',
				description: 'Cash Flow Class',
			},
			{
				field: 'cft',
				headerName: 'CFT',
				description: 'Cash Flow Type',
				width: DataGridColumnWidths.threeChar,
				renderCell: (value) => {
					return (
						<Tooltip title={value.row.cftName}>
							<Typography variant="body2" noWrap>
								{value.row.cft}
							</Typography>
						</Tooltip>
					);
				},
			},
			{
				field: 'cftName',
				headerName: 'CFT Description',
				description: 'Cash Flow Type',
			},
			{
				field: 'cfst',
				headerName: 'CFST',
				description: 'Cash Flow Subtype',
				width: DataGridColumnWidths.fourChar,
				renderCell: (value) => {
					return (
						<Tooltip title={value.row.cfstName}>
							<Typography variant="body2" noWrap>
								{value.row.cfst}
							</Typography>
						</Tooltip>
					);
				},
			},
			{
				field: 'cfstName',
				headerName: 'CFST Description',
				description: 'Cash Flow Subtype',
			},
			{
				field: 'glNumber',
				headerName: 'GL Code',
			},
			{
				...getCurrencyColumnDefinition(),
				field: 'number',
				headerName: 'Amount',
				valueGetter: (
					params: GridValueGetterParams<TransactionListItem, TransactionValue>,
				) => {
					if (currencyOption.id === 'original') {
						return params.value?.value;
					} else {
						return params.value?.reportingValue;
					}
				},
				renderCell: (params) => {
					let currencyCode: string | undefined = params.row.currency;
					if (currencyOption.id === 'reporting') {
						currencyCode = configurations?.reportingCurrencyCode || 'USD';
					}
					return formatCurrency(params.value, {
						currency: currencyCode,
					});
				},
			},
			{
				field: 'currency',
				headerName: 'Currency',
				renderCell: (params) => {
					return currencyOption.id === 'original'
						? params.row.currency?.toUpperCase()
						: configurations?.reportingCurrencyCode || 'USD';
				},
			},
		];

		if (projectedTransactionsEnabled) {
			columns.push({
				field: 'reconciliationStatus',
				headerName: 'Reconciliation Status',
				valueGetter: (params) => normalizeReconciliationStatus(params.value),
				renderCell: (params) => {
					return (
						<Tooltip
							title={
								params.value ===
								normalizeReconciliationStatus(ReconciliationStatus.Posted)
									? 'The cleared transaction has no accociated records.'
									: 'Has associated records.'
							}
						>
							<Typography
								onClick={() => {
									if (params.row.reconciliationRecordId) {
										viewReconciliation(params.row.reconciliationRecordId);
									}
								}}
								sx={(theme) => ({
									textDecoration: params.row.reconciliationRecordId
										? 'underline'
										: undefined,
									color: params.row.reconciliationRecordId
										? theme.palette.denim[500]
										: undefined,
									lineHeight: 1.43,
									letterSpacing: '0.01071em',
									fontSize: '0.875rem',
									cursor: params.row.reconciliationRecordId
										? 'pointer'
										: undefined,
								})}
							>
								{params.value}
							</Typography>
						</Tooltip>
					);
				},
			});
		}

		columns.push(
			{
				field: 'bankName',
				headerName: 'Counterparty Name',
			},
			{
				field: 'bankCode',
				headerName: 'Counterparty Code',
			},
			{
				...getFormattedDateTimeColumnDefinition(),
				field: 'importedDate',
				headerName: 'Imported Date',
			},
		);

		if (user.cash4.isAuthor) {
			columns.push({
				field: 'isForecastModelExcluded',
				headerName: 'Forecast Reporting Model',
				valueGetter: (params) => (params.value ? 'Excluded' : 'Included'),
			});
		}

		return columns;
	}, [
		configurations?.reportingCurrencyCode,
		currencyOption.id,
		handleCashTransactionDeleteClick,
		handleTransactionViewClick,
		projectedTransactionsEnabled,
		user.cash4.isAuthor,
		viewReconciliation,
	]);

	const projectedTransactionsColumns = useMemo<GridColDef[]>(() => {
		const projectedTransactionsColumns: GridColDef<ProjectedItem>[] = [
			{
				field: 'actions',
				headerName: '',
				width: 25,
				renderCell: (params) => {
					return (
						<Actions
							stonlyId={stonlyIds.projectedTransactionsRowContextMenu}
							actions={[
								{
									action: ActionsEnum.view,
									callback: () => handleProjectedViewClick(params.row),
								},
								...(user.cash4.isAuthor
									? [
											{
												action: ActionsEnum.edit,
												callback: () => handleProjectedEditClick(params.row),
											},
									  ]
									: []),
								...(user.cash4.isAuthor
									? [
											{
												action: ActionsEnum.delete,
												isDisabled: params.row.reconciliationId
													? true
													: undefined,
												isDisabledTooltipText:
													'This record is associated with a reconciliation and cannot be deleted. Edit reconciliation to remove association.',
												callback: () =>
													handleProjectedTransactionDeleteClick(params.row),
											},
									  ]
									: []),
							]}
							id="projectedTransactionId-more-menu"
						/>
					);
				},
				resizable: false,
				sortable: false,
				disableColumnMenu: true,
				filterable: false,
				disableExport: true,
				hideable: false,
				disableReorder: true,
			},
			{
				field: 'label',
				headerName: 'Label',
			},
			{
				field: 'reconciliationStatus',
				headerName: 'Reconciliation Status',
				renderCell: (params) => {
					return (
						<Tooltip
							title={
								params.value === 'Unreconciled'
									? 'Preform reconciliation to associate records.'
									: 'Has associated records.'
							}
						>
							<Typography
								onClick={() => {
									if (params.row.reconciliationId) {
										viewReconciliation(params.row.reconciliationId);
									}
								}}
								sx={(theme) => ({
									textDecoration: params.row.reconciliationId
										? 'underline'
										: undefined,
									color: params.row.reconciliationId
										? theme.palette.denim[500]
										: undefined,
									lineHeight: 1.43,
									letterSpacing: '0.01071em',
									fontSize: '0.875rem',
									cursor: params.row.reconciliationId ? 'pointer' : undefined,
								})}
							>
								{normalizeReconciliationStatus(params.value)}
							</Typography>
						</Tooltip>
					);
				},
			},
			{
				field: 'description',
				headerName: 'Description',
			},
			{
				field: 'flowDirection',
				headerName: 'Fund Direction',
			},
			{
				...getCurrencyColumnDefinition(),
				field: 'amount',
				headerName: 'Amount',
				renderCell: (params) =>
					formatCurrency(params.row.amount, {
						currency: params.row.currencyCode,
					}),
			},
			{
				field: 'currencyCode',
				headerName: 'Currency Code',
			},
			{
				...getDateColumnDefinition(),
				field: 'date',
				headerName: 'Expected Value Date',
			},
			{
				field: '_cfc',
				headerName: 'CFC',
				description: 'Cash Flow Class',
				width: DataGridColumnWidths.threeChar,
				valueGetter: (params) => params.row?.categorization?.class?.code,
				renderCell: (params) => {
					return (
						<Tooltip title={params.row.categorization?.class?.name}>
							<Typography variant="body2" noWrap>
								{params.value}
							</Typography>
						</Tooltip>
					);
				},
			},
			{
				field: '_cfcName',
				headerName: 'CFC Description',
				description: 'Cash Flow Class',
				valueGetter: (params) => params.row?.categorization?.class?.name,
			},
			{
				field: '_cft',
				headerName: 'CFT',
				description: 'Cash Flow Type',
				width: DataGridColumnWidths.threeChar,
				valueGetter: (params) => params.row?.categorization?.type?.code,
				renderCell: (params) => {
					return (
						<Tooltip title={params.row.categorization?.type?.name}>
							<Typography variant="body2" noWrap>
								{params.value}
							</Typography>
						</Tooltip>
					);
				},
			},
			{
				field: '_cftName',
				headerName: 'CFT Description',
				description: 'Cash Flow Type',
				valueGetter: (params) => params.row?.categorization?.type?.name,
			},
			{
				field: '_cfst',
				headerName: 'CFST',
				description: 'Cash Flow Subtype',
				width: DataGridColumnWidths.fourChar,
				valueGetter: (params) => params.row?.categorization?.subtype?.code,
				renderCell: (params) => {
					return (
						<Tooltip title={params.row.categorization?.subtype?.name}>
							<Typography variant="body2" noWrap>
								{params.value}
							</Typography>
						</Tooltip>
					);
				},
			},
			{
				field: '_cfstName',
				headerName: 'CFST Description',
				description: 'Cash Flow Subtype',
				valueGetter: (params) => params.row?.categorization?.subtype?.name,
			},
			{
				field: '_glCode',
				headerName: 'GL Code',
				valueGetter: (params) => params.row?.categorization?.glCode?.code,
			},
			{
				field: '_primaryParty_name',
				headerName: 'Entity',
				valueGetter: (params) => params.row.primaryParty.object?.name,
			},
			{
				field: '_primaryParty__account_name',
				headerName: 'Account',
				valueGetter: (params) => params.row.primaryParty.account?.name,
			},
			{
				field: '_primaryParty__account_number',
				headerName: 'Account Number',
				valueGetter: (params) => params.row.primaryParty.account?.number,
			},
			{
				field: '_secondaryParty_type',
				headerName: 'Type',
				valueGetter: (params) => params.row.secondaryParty?.type,
			},
			{
				field: '_secondaryParty_name',
				headerName: 'Secondary Entity',
				valueGetter: (params) => params.row.secondaryParty?.object?.name,
			},
			{
				field: '_secondaryParty__account_name',
				headerName: 'Secondary Account',
				valueGetter: (params) => params.row.secondaryParty?.account?.name,
			},
			{
				field: '_secondaryParty__account_number',
				headerName: 'Secondary Account Number',
				valueGetter: (params) => params.row.secondaryParty?.account?.number,
			},
		];

		if (user.cash4.isAuthor) {
			projectedTransactionsColumns.push({
				field: 'isForecastModelExcluded',
				headerName: 'Forecast Reporting Model',
				valueGetter: (params) => (params.value ? 'Excluded' : 'Included'),
			});
		}

		return projectedTransactionsColumns;
	}, [
		handleProjectedEditClick,
		handleProjectedTransactionDeleteClick,
		handleProjectedViewClick,
		viewReconciliation,
		user.cash4.isAuthor,
	]);

	const customNoProjectedTransactionsOverlay = useMemo(
		() => () => (
			<NoRowsOverlay
				icon={Balance}
				heading={
					<Typography variant="h3">No Projected Transactions</Typography>
				}
				body={
					user.cash4.isAuthor ? (
						<Typography variant="body1">
							Select{' '}
							<Link
								underline="always"
								color={brandColors.denim[500]}
								sx={{ cursor: 'pointer' }}
								onClick={() => setIsCreatedEditDrawerOpen(true)}
							>
								Create Projection
							</Link>{' '}
							to add your data, or adjust the date range to view additional
							transactions.
						</Typography>
					) : (
						<Typography variant="body1">
							Adjust your date range to view projected transactions or contact
							your administrator to add data.
						</Typography>
					)
				}
			/>
		),
		[],
	);

	const reconcileButton = useMemo(
		() => (
			<T4Button
				variant="outlined"
				startIcon={<SwapHoriz />}
				onClick={() => {
					startReconciliationCreation();
				}}
			>
				Reconcile
			</T4Button>
		),
		[startReconciliationCreation],
	);

	const updateCurrentDayButton = useMemo(
		() => (
			<T4Button
				variant="outlined"
				startIcon={<CurrencyExchange />}
				onClick={() => {
					updateCurrentDay();
				}}
			>
				Update
			</T4Button>
		),
		[updateCurrentDay],
	);

	const reportedGrid = useMemo(
		() => (
			<UserPreferencesDataGrid
				tableKey="transactionsPage"
				columns={columns}
				stonlyId="transactions"
				rows={data || []}
				loading={isLoading}
				calculateColumnWidths
				columnVisibilityModel={{
					c4AccountNumber: false,
					bankName: false,
					bankCode: false,
					bankReference: false,
					checkNumber: false,
					glNumber: false,
					customerReference: false,
					transactionImportedDate: false,
					transactionRuleName: false,
					fiTransactionId: false,
					noteContent: false,
					cfcName: false,
					cftName: false,
					cfstName: false,
					isForecastModelExcluded: false,
				}}
				getRowClassName={(
					params: GridRowClassNameParams<TransactionListItem>,
				) => (!params.row.cfc ? 'uncategorized-row' : '')}
				initialState={{
					sorting: {
						sortModel: [
							{ field: 'date', sort: 'desc' },
							{ field: 'e4AccountNumber', sort: 'asc' },
							{ field: 'transactionCode', sort: 'asc' },
						],
					},
				}}
				pinnedColumns={{ left: ['actions'] }}
				showToolbar
				showCustomViewButton
			/>
		),
		[columns, data, isLoading],
	);

	const projectedGrid = useMemo(
		() => (
			<UserPreferencesDataGrid
				stonlyId={stonly.projectedTransactionsGrid}
				tableKey="projectedTransactionsPage"
				loading={projectedItemsLoading}
				columns={projectedTransactionsColumns}
				columnVisibilityModel={{
					isForecastModelExcluded: false,
					_cfcName: false,
					_cftName: false,
					_cfstName: false,
				}}
				rows={projectedItems || []}
				calculateColumnWidths
				initialState={{
					sorting: {
						sortModel: [
							{
								field: 'date',
								sort: 'desc',
							},
						],
					},
				}}
				pinnedColumns={{ left: ['actions'] }}
				showToolbar
				showCustomViewButton
				pagination
				hideFooter={false}
				autoPageSize
				slots={{ noRowsOverlay: customNoProjectedTransactionsOverlay }}
			/>
		),
		[
			projectedItems,
			projectedItemsLoading,
			projectedTransactionsColumns,
			customNoProjectedTransactionsOverlay,
		],
	);

	const getTransactionActions = useMemo((): Action[] | undefined => {
		if (user.cash4.isAuthor) {
			return [
				{
					action: ActionsEnum.delete,
					isDisabled: transaction?.reconciliationRecordId ? true : undefined,
					isDisabledTooltipText:
						'This record is associated with a reconciliation and cannot be deleted. Edit reconciliation to remove association.',
					callback: () => handleCashTransactionDeleteClick(transaction!),
				},
			];
		} else {
			return undefined;
		}
	}, [handleCashTransactionDeleteClick, transaction, user.cash4.isAuthor]);

	const getProjectedTransactionActions = useMemo((): Action[] | undefined => {
		if (user.cash4.isAuthor) {
			return [
				{
					action: ActionsEnum.delete,
					isDisabled: projectedItem?.reconciliationId ? true : undefined,
					isDisabledTooltipText:
						'This record is associated with a reconciliation and cannot be deleted. Edit reconciliation to remove association.',
					callback: () => handleProjectedTransactionDeleteClick(projectedItem!),
				},
				{
					action: ActionsEnum.edit,
					callback: () => handleProjectedEditClick(projectedItem!),
				},
			];
		} else {
			return undefined;
		}
	}, [
		handleProjectedTransactionDeleteClick,
		handleProjectedEditClick,
		projectedItem,
		user.cash4.isAuthor,
	]);

	useEffect(() => {
		if (!tabValue) {
			history.push(history.location.pathname + `?tab=${'reported'}`);
		}
	}, [history, tabValue]);

	return (
		<T4View
			header={
				<PageHeader
					id={pageHeaderStonlyIds.transactionsPage}
					title="Transactions"
				/>
			}
		>
			<TabContext value={tabValue}>
				<Grid
					container
					sx={{
						gap: 2,
						flexDirection: 'column',
						flexWrap: 'nowrap',
						height: '100%',
						width: '100%',
					}}
				>
					<Grid container item xs="auto">
						<TabList
							onChange={(_, tab) => {
								const setTab = (tab: TransactionPageTab) => {
									history.push(history.location.pathname + `?tab=${tab}`);
								};

								if (tab === 'reported') {
									setTab(tab);
								} else if (tab === 'projected') {
									setTab(tab);
								} else {
									setTab('reported');
								}
							}}
						>
							<Tab label="Reported" value="reported" />
							{projectedTransactionsEnabled && (
								<Tab label="Projected" value="projected" />
							)}
						</TabList>
					</Grid>
					<Grid item xs={true}>
						<TabPanel value="reported" sx={{ height: '100%', padding: 0 }}>
							<Grid
								item
								container
								sx={{
									gap: 2,
									height: '100%',
									flexDirection: 'column',
									flexWrap: 'nowrap',
								}}
							>
								<Grid
									container
									item
									xs="auto"
									sx={{ justifyContent: 'space-between' }}
								>
									<Grid
										container
										item
										xs="auto"
										sx={{ gap: 2, alignItems: 'flex-end' }}
									>
										<Grid item xs="auto">
											<T4DateRangePicker
												defaultValue={dateRange}
												onAccept={([startDate, endDate]) => {
													if (
														startDate &&
														startDate.isValid() &&
														endDate &&
														endDate.isValid() &&
														startDate.isSameOrBefore(endDate)
													) {
														if (
															!startDate.isSame(dateRange[0]) ||
															!endDate.isSame(dateRange[1])
														) {
															handleDateRangeChange([startDate, endDate]);
														}
													}
												}}
												maxDate={moment()}
												disableFuture
												showShortcuts
												shortcutResetDefaults={defaultDateRange}
												sx={{ marginBottom: 0 }}
											/>
										</Grid>
										<Grid item xs="auto">
											<T4TextFieldV2
												label="Display Currency"
												select
												value={currencyOption.id}
												onChange={(event) => {
													handleCurrencyOptionChange(
														event as CurrencyOption['id'],
													);
												}}
											>
												{[
													{ id: 'original', value: 'Account Currency' },
													{
														id: 'reporting',
														value: `Reporting Currency (${
															configurations?.reportingCurrencyCode || 'USD'
														})`,
													},
												].map((x) => {
													return (
														<MenuItem key={x.id} value={x.id}>
															{x.value}
														</MenuItem>
													);
												})}
											</T4TextFieldV2>
										</Grid>
										<Grid item xs="auto">
											<LegalEntityGroupsFilter loading={isLoading} />
										</Grid>
									</Grid>
									<Grid
										container
										item
										xs="auto"
										sx={{ gap: 2, alignItems: 'flex-end' }}
									>
										{user.cash4.isAuthor && currentDayRefreshStatus && (
											<Tooltip title={lastRefreshedDate}>
												<Grid item xs="auto">
													{updateCurrentDayButton}
												</Grid>
											</Tooltip>
										)}
										{user.cash4.isAuthor && projectedTransactionsEnabled && (
											<Grid item xs="auto">
												{createProjectedItemButton}
											</Grid>
										)}
										{user.cash4.isAuthor && projectedTransactionsEnabled && (
											<Grid item xs="auto">
												{reconcileButton}
											</Grid>
										)}
									</Grid>
								</Grid>
								{error && (
									<Grid item xs="auto">
										<T4AlertStack error={error as string} />
									</Grid>
								)}
								<Grid item xs={true}>
									<ActuallyPrettyGoodDataGridWrapper
										sx={{
											height: '100%',
											'& .uncategorized-row': {
												backgroundColor: '#FFFDE3',
											},
											'& .MuiDataGrid-columnHeaders': {
												height: '3rem !important',
												maxHeight: '3rem !important',
											},
											'& .MuiDataGrid-columnHeader': {
												height: '3rem !important',
												maxHeight: '3rem !important',
											},
											'& .MuiDataGrid-columnHeadersInner': {
												height: '3rem !important',
												maxHeight: '3rem !important',
											},
											'& .MuiDataGrid-columnHeaderTitle': {
												height: '3rem !important',
												maxHeight: '3rem !important',
												display: 'flex',
												flexWrap: 'wrap',
												whiteSpace: 'break-spaces',
												lineHeight: '1.5rem',
												alignContent: 'center',
											},
										}}
									>
										{reportedGrid}
									</ActuallyPrettyGoodDataGridWrapper>
								</Grid>
							</Grid>
						</TabPanel>

						<TabPanel value="projected" sx={{ height: '100%', padding: 0 }}>
							<Grid
								item
								container
								sx={{
									gap: 2,
									height: '100%',
									flexDirection: 'column',
									flexWrap: 'nowrap',
								}}
							>
								<Grid
									container
									item
									xs="auto"
									sx={{ justifyContent: 'space-between' }}
								>
									<Grid
										container
										item
										xs="auto"
										sx={{ gap: 2, alignItems: 'flex-end' }}
									>
										<Grid item xs="auto">
											<T4DateRangePicker
												defaultValue={projectedItemDateRange}
												onAccept={([startDate, endDate]) => {
													if (
														startDate &&
														startDate.isValid() &&
														endDate &&
														endDate.isValid() &&
														startDate.isSameOrBefore(endDate)
													) {
														if (
															!startDate.isSame(dateRange[0]) ||
															!endDate.isSame(dateRange[1])
														) {
															handleProjectedItemDateRangeChange([
																startDate,
																endDate,
															]);
														}
													}
												}}
												showShortcuts
												sx={{ marginBottom: 0 }}
											/>
										</Grid>
										<Grid item xs="auto">
											<LegalEntityGroupsFilter loading={isLoading} />
										</Grid>
									</Grid>
									<Grid
										container
										item
										xs="auto"
										sx={{
											gap: 2,
											justifyContent: 'flex-end',
											alignContent: 'flex-end',
										}}
									>
										{user.cash4.isAuthor && (
											<Grid item xs="auto">
												{createProjectedItemButton}
											</Grid>
										)}
										{user.cash4.isAuthor && (
											<Grid item xs="auto">
												{reconcileButton}
											</Grid>
										)}
									</Grid>
								</Grid>
								<Grid item xs={true}>
									<ActuallyPrettyGoodDataGridWrapper>
										{projectedGrid}
									</ActuallyPrettyGoodDataGridWrapper>
								</Grid>
							</Grid>
						</TabPanel>
					</Grid>
				</Grid>
			</TabContext>

			<C4TransactionsDrawer
				isOpen={openTransactionDrawer}
				onClose={handleCloseTransactionDrawer}
				transactionId={transactionId}
				disableLink={false}
				transactionActions={getTransactionActions}
			/>

			<T4Drawer
				open={openProjectionDrawer}
				onClose={handleCloseProjectionDrawer}
				stonlyPrefix="projected-transaction-details"
			>
				<ProjectionDrawer
					projectionId={projectionId}
					projectedItemActions={getProjectedTransactionActions}
				/>
			</T4Drawer>

			<ReconciliationDrawer onSuccess={refetchAllTransactions} />

			<C4ProjectedEditModal
				isOpen={isEditWarningOpen}
				onCancel={() => {
					if (!openProjectionDrawer) {
						setProjectedItem(undefined);
					}
					setIsCreatedEditDrawerOpen(false);
					setIsEditWarningOpen(false);
				}}
				onConfirm={() => {
					setOpenProjectionDrawer(false);
					setIsCreatedEditDrawerOpen(true);
					setIsEditWarningOpen(false);
				}}
			/>

			<ConfirmationDialog
				open={openDialog}
				onClose={handleCloseDialog}
				title={selectedDialog.title || ''}
				text={selectedDialog.text}
				primaryButtonText={selectedDialog.primaryButtonText}
				secondaryButtonText={selectedDialog.secondaryButtonText}
				onPrimaryButtonClick={selectedDialog.onPrimaryButtonClick}
				onSecondaryButtonClick={selectedDialog.onSecondaryButtonClick}
				stonlyIds={{
					confirmationModal: stonlyIds.transactionsDeleteConfirmationModal,
					secondaryButton: stonlyIds.transactionsDeleteConfirmationCancelButton,
					primaryButton: stonlyIds.transactionsDeleteConfirmationDeleteButton,
				}}
				loading={confirmationModalLoading}
			/>
			{CreateEditDrawer}
		</T4View>
	);
});
