import * as go from 'gojs';
import { observer } from 'mobx-react-lite';
import { FC, useMemo } from 'react';
import { T4Diagram, T4DiagramProps } from '../../_shared/_components/t4Diagram';
import {
	getDiagram,
	setupDiagramEvents,
} from '../../_shared/_templates/diagram';
import { link } from '../../_shared/_templates/link';
import { textColor } from '../../_shared/constants';
import { accountMapLegend } from '../_templates/accountMapLegend';
import { accountNode } from '../_templates/accountNode';
import { slimLegalEntityNode } from '../_templates/slimLegalEntityNode';
import { standaloneAccounts } from '../_templates/standaloneAccounts';
import { ArrangingLayout } from './arrangingLayout';
import { EntityViewLayout } from './entityViewLayout';

export const AccountMapEntityViewRenderer: FC<
	Pick<T4DiagramProps, 'diagramListeners' | 'nodeDataArray' | 'linkDataArray'>
> = observer(({ diagramListeners, ...rest }) => {
	go.GraphObject.defineBuilder(
		'EntityViewTreeExpanderButton',
		(_): go.Panel => {
			const button = go.GraphObject.build('Button') as go.Panel;
			button.attach({
				// set these values for the isTreeExpanded binding conversion
				_treeExpandedFigure: 'MinusLine',
				_treeCollapsedFigure: 'PlusLine',
				// assume initially not visible because there are no links coming out
				visible: false,
			});

			button.add(
				// Group to hold both the shape and the text
				go.GraphObject.make(
					go.Panel,
					'Horizontal',
					go.GraphObject.make(
						go.TextBlock,
						{
							font: '4pt sans-serif',
							stroke: textColor,
							margin: new go.Margin(0, 1, -2, 0),
						},
						new go.Binding(
							'text',
							'',
							(n) => n.data.countChildren || '',
						).ofObject(),
					),

					// Shape for plus/minus icon
					new go.Shape('MinusLine', {
						name: 'ButtonIcon',
						stroke: '#0a0a0a',
						strokeWidth: 1,
						desiredSize: new go.Size(4, 4),
					}) // bind the Shape.figure to the Node.isTreeExpanded value using this converter:
						.bind(
							new go.Binding('figure', 'isTreeExpanded', function (exp) {
								return exp ? 'MinusLine' : 'PlusLine';
							}).ofObject(),
						),
				),
			);

			// bind the button visibility to whether it's not a leaf node
			button.bindObject(
				'visible',
				'isTreeLeaf',
				(leaf: boolean): boolean => !leaf,
			);

			// tree expand/collapse behavior
			button.click = (e: go.InputEvent, btn: go.GraphObject): void => {
				//intentionally suppress collapse/expand as it isn't done today.
				let node = btn.part;
				if (node instanceof go.Adornment) node = node.adornedPart;
				if (!(node instanceof go.Node)) return;
				const diagram = node.diagram;
				if (diagram === null) return;
				const cmd = diagram.commandHandler;
				if (node.isTreeExpanded) {
					if (!cmd.canCollapseTree(node)) return;
				} else {
					if (!cmd.canExpandTree(node)) return;
				}
				e.handled = true;
				if (node.isTreeExpanded) {
					cmd.collapseTree(node);
				} else {
					cmd.expandTree(node);
				}
			};

			return button;
		},
	);

	const diagram = useMemo(() => {
		const diagram = getDiagram(
			{
				layout: new ArrangingLayout({
					spacing: new go.Size(400, 50),
					side: go.Spot.RightSide,
					primaryLayout: new EntityViewLayout({
						arrangement: go.TreeArrangement.Horizontal,
						treeStyle: go.TreeStyle.LastParents,
						angle: 90,
						alternateAngle: 90,
						alternateAlignment: go.TreeAlignment.Bus,
						alternateNodeSpacing: 50,
						arrangementSpacing: new go.Size(200, 50),
					}),
					arrangingLayout: new go.TreeLayout({
						angle: 90,
						arrangement: go.TreeArrangement.Horizontal,
						arrangementSpacing: new go.Size(50, 50),
					}),
					sideLayout: new go.TreeLayout({
						arrangement: go.TreeArrangement.Horizontal,
						arrangementSpacing: new go.Size(50, 50),
					}),
					filter: (part) => {
						let node = part as go.Node;
						if (node.linksConnected?.count === 0) return false;
						return node.linksConnected?.any(
							(link) => !link.category?.startsWith('RootToRoot'),
						);
					},
				}),
				linkTemplate: link(true),
				nodeTemplateMap: new go.Map([
					{ key: '', value: accountNode() },
					{ key: 'entityOrg', value: slimLegalEntityNode() },
				]),
				groupTemplateMap: new go.Map([
					{ key: '', value: standaloneAccounts() },
					{ key: 'Legend', value: accountMapLegend() },
				]),
			},
			diagramListeners,
		);
		setupDiagramEvents(diagram);

		diagram.toolManager.mouseWheelBehavior = go.WheelMode.Zoom;
		diagram.undoManager.isEnabled = true;

		return diagram;
	}, [diagramListeners]);

	return (
		<T4Diagram
			divClassName="entityview-gojs-diagram"
			style={{ background: 'white' }}
			initDiagram={() => diagram}
			nodeDataArray={rest.nodeDataArray}
			linkDataArray={rest.linkDataArray}
		/>
	);
});
