import { debounce, Grid } from '@mui/material';
import go from 'gojs';
import { ReactOverview } from 'gojs-react';
import { observer } from 'mobx-react-lite';
import {
	VisualizationPreferenceNodeData,
	VisualizationPreferenceOption,
} from 'modules/clients/customer-api/src/api/visualizations';
import { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { T4View } from 'shared/components/t4View';
import { orgChartPrefix } from 'stonly/pagePrefixes';
import { VisualizationsFooter } from '../_shared/_components/visualizationFooter';
import { useVisualizationDispatch } from '../_shared/_providers/visualizationProvider';
import { OrgChartHeaderComponent } from './_shared/_components/orgChartHeader';
import { OrgChartRenderer } from './_shared/_components/orgChartRenderer';
import { useOrgChart } from './_shared/_providers/orgChartProvider';
import {
	DisplayCompactKey,
	DisplayOverviewMapKey,
} from './models/orgChartTypes';

export const OrgChartPage: FC = observer(() => {
	const {
		isLoading,
		nodes,
		links,
		optionPreferences,
		nodePreferences,
		updatePreferences,
	} = useOrgChart();
	const setZoom = useVisualizationDispatch();

	const [diagram, setDiagram] = useState<go.Diagram>();
	const [showOverviewMap, setShowOverviewMap] = useState<boolean>(true);

	//#region Option Preferences

	const applyCompactPreference = useCallback(
		(currentDiagram: go.Diagram, isCompactView: boolean) => {
			if (isCompactView) {
				(currentDiagram.layout as go.TreeLayout).alignment =
					go.TreeAlignment.Bus;
			} else {
				(currentDiagram.layout as go.TreeLayout).alignment =
					go.TreeAlignment.CenterChildren;
			}
		},
		[],
	);

	const applyOverviewPreference = useCallback((isOverviewVisible: boolean) => {
		setShowOverviewMap(isOverviewVisible);
	}, []);

	const applyNodeDataState = useCallback(
		(
			currentDiagram: go.Diagram,
			currentModel: go.Model,
			currentNodeDataState: VisualizationPreferenceNodeData[],
		) => {
			currentDiagram.nodes.each((node) => {
				const nodeState = currentNodeDataState.find(
					(x) => x.key === node.data.key,
				);
				if (nodeState) {
					if (nodeState.x && nodeState.y) {
						currentModel.set(
							node.data,
							'loc',
							new go.Point(nodeState.x, nodeState.y),
						);
					}
				} else {
					currentModel.set(node.data, 'loc', undefined);
				}
			});
		},
		[],
	);

	const loadPreferences = useCallback(
		(
			currentOptionPreferences: VisualizationPreferenceOption[],
			currentDiagram: go.Diagram,
		) => {
			const getOption = (optionId: string) => {
				return currentOptionPreferences.find(
					(option) => option.optionId === optionId,
				);
			};

			const isOptionVisible = (optionId: string) => {
				return !(getOption(optionId)?.hide ?? false);
			};

			const isOverviewVisible = isOptionVisible(DisplayOverviewMapKey);
			const isCompactView = isOptionVisible(DisplayCompactKey);

			currentDiagram.commit((d) => {
				d.model.commit((m) => {
					applyCompactPreference(d, isCompactView);
					applyOverviewPreference(isOverviewVisible);
				});
			});
		},
		[applyCompactPreference, applyOverviewPreference],
	);

	const loadNodeLocationPreferences = useCallback(
		(
			currentPreferences: VisualizationPreferenceNodeData[],
			currentDiagram: go.Diagram,
		) => {
			currentDiagram.commit((d) => {
				d.model.commit((m) => {
					applyNodeDataState(d, m, currentPreferences ?? []);
				});
			});
		},
		[applyNodeDataState],
	);

	//#endregion

	//#region Node Preferences

	const updateNodeData = useCallback(
		(currentDiagram: go.Diagram) => {
			const nextNodePreferences: VisualizationPreferenceNodeData[] = [];
			currentDiagram.nodes
				.filter(
					(node) =>
						node.visible &&
						node.position.x !== null &&
						!Number.isNaN(node.position.x) &&
						node.position.y !== null &&
						!Number.isNaN(node.position.y),
				)
				.each((node) => {
					nextNodePreferences.push({
						id: node.data.key,
						key: node.data.key,
						x: node.location.x,
						y: node.location.y,
						visible: true,
					});
				});

			updatePreferences({
				nodes: nextNodePreferences,
			});
		},
		[updatePreferences],
	);

	//#endregion

	//#endregion

	const selectionMoved = useCallback(
		(event: go.DiagramEvent) => {
			updateNodeData(event.diagram);
		},
		[updateNodeData],
	);

	const onViewportBoundsChangedHandler = useMemo(
		() =>
			debounce((event: go.DiagramEvent) => {
				setZoom(event.diagram.scale);
			}, 200),
		[setZoom],
	);

	useEffect(() => {
		if (diagram) {
			loadPreferences(optionPreferences, diagram);
		}
	}, [diagram, loadPreferences, optionPreferences]);

	useEffect(() => {
		if (diagram) {
			loadNodeLocationPreferences(nodePreferences, diagram);
		}
	}, [diagram, loadNodeLocationPreferences, nodePreferences]);

	return (
		<T4View loading={isLoading} disablePadding>
			<Grid
				container
				flexDirection="column"
				sx={{ height: '100%', width: '100%', overflow: 'hidden' }}
			>
				<Grid item xs="auto">
					<OrgChartHeaderComponent diagram={diagram} />
				</Grid>
				<Grid
					item
					xs={true}
					sx={{
						position: 'relative',
						boxSizing: 'unset',

						canvas: {
							outline: 'none',
						},
						'.orgchart-gojs-diagram': {
							width: '100%',
							height: '100%',
						},
					}}
				>
					{showOverviewMap && (
						<ReactOverview
							initOverview={() =>
								new go.Overview(undefined, {
									contentAlignment: go.Spot.Center,
								})
							}
							divClassName="overview"
							style={{
								backgroundColor: '#eee',
								height: '150px',
								width: '250px',
								position: 'absolute',
								top: '2rem',
								left: '2rem',
								border: 'solid',
								borderWidth: '1px',
								borderColor: 'black',
								zIndex: 10,
								outline: 'none',
							}}
							observedDiagram={diagram ?? null}
						/>
					)}
					<OrgChartRenderer
						nodeDataArray={nodes as any[]}
						linkDataArray={links}
						diagramListeners={[
							['InitialLayoutCompleted', (event) => setDiagram(event.diagram)],
							['ViewportBoundsChanged', onViewportBoundsChangedHandler],
							['SelectionMoved', selectionMoved],
						]}
					/>
				</Grid>
				<Grid
					item
					xs="auto"
					sx={{
						paddingBottom: '1rem',
						justifyContent: 'center',
						position: 'absolute',
						bottom: '1rem',
						zIndex: 5,
					}}
				>
					<VisualizationsFooter
						stonlyId={orgChartPrefix}
						diagram={diagram}
						onResetView={() => {
							if (diagram) {
								updatePreferences({
									nodes: [],
								});
								loadPreferences(optionPreferences, diagram);
							}
						}}
					/>
				</Grid>
			</Grid>
		</T4View>
	);
});
