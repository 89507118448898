import { Add, ArrowDropDown, Cached, FolderOpen } from '@mui/icons-material';
import {
	Alert,
	Breadcrumbs,
	Divider,
	Grid,
	Menu,
	MenuItem,
	Typography,
} from '@mui/material';
import { observer } from 'mobx-react-lite';
import { FC, useEffect, useState } from 'react';
import { CannotDisplay } from 'shared/components/cannotDisplay';
import { T4View } from 'shared/components/t4View';
import { NOT_FOUND_MESSAGING } from 'shared/constants/cannotDisplayMessaging';
import { AddFolderButton } from 'shared/dashboardPage/addFolderButton';
import { AddReportButton } from 'shared/dashboardPage/addReportButton';
import { DashboardOptions } from 'shared/dashboardPage/dashboardOptions';
import { useSigma } from '../_providers/sigmaProvider';
import { SigmaButton } from './sigmaButton';

export const SigmaEmbed: FC = observer(() => {
	const {
		initializing,
		sigmaUrl,
		workbooks,
		workbook,
		folders,
		folder,
		generateSigmaUrl,
		selectFolder,
		selectWorkbook,
		path,
		loadingNewItem,
	} = useSigma();

	const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
	const [openFolderAdd, setOpenFolderAdd] = useState(false);
	const [openReportAdd, setOpenReportAdd] = useState(false);
	const [showWarning, setShowWarning] = useState(true);

	const [menuId, setMenuId] = useState<string | null>(null);
	const [lastOpened, setLastOpened] = useState<string | null>(null);

	useEffect(() => {
		if (lastOpened !== menuId && menuId) {
			setLastOpened(menuId);
		}
	}, [menuId, lastOpened]);

	const handleClickBreadcrumb = (
		event: React.MouseEvent<HTMLElement>,
		id: string,
	) => {
		setMenuId(id);
		setAnchorEl(event.currentTarget);
	};

	const handleCloseMenu = () => {
		setAnchorEl(null);
		setMenuId(null);
	};

	const handleOpenFolderAdd = () => {
		handleCloseMenu();
		setOpenFolderAdd(true);
	};

	const handleOpenReportAdd = () => {
		handleCloseMenu();
		setOpenReportAdd(true);
	};

	const renderMenu = (id: string, parentId: string) => {
		const folder = folders.find((folder) => folder.id === id);
		const filteredFolders = folders.filter((folder) => folder.parentId === id);
		const filteredWorkbooks = workbooks.filter(
			(workbook) => workbook.folderId === id,
		);

		const menuItems = [];

		if (filteredFolders.length > 0) {
			menuItems.push(
				<Typography
					variant="subtitle2"
					key="folders-label"
					sx={{ paddingLeft: 1, color: 'text.secondary' }}
				>
					Folders
				</Typography>,
			);

			menuItems.push(
				...filteredFolders.map((folder) => (
					<MenuItem
						key={folder.id}
						onClick={() => {
							selectFolder(folder);
							handleCloseMenu();
						}}
						sx={{ paddingLeft: '32px' }}
					>
						{folder.name}
					</MenuItem>
				)),
			);
		}
		if (folder?.permission === 'edit') {
			menuItems.push(
				<MenuItem key="create-folder">
					<SigmaButton
						variant="outlined"
						startIcon={<FolderOpen />}
						onClick={handleOpenFolderAdd}
						sx={{ width: '100%' }}
					>
						Create Folder
					</SigmaButton>
				</MenuItem>,
			);

			menuItems.push(<Divider key="reports-divider" />);
		}
		if (filteredWorkbooks.length > 0) {
			menuItems.push(
				<Typography
					variant="subtitle2"
					key="reports-label"
					sx={{ paddingLeft: 1, color: 'text.secondary' }}
				>
					Reports
				</Typography>,
			);

			menuItems.push(
				...filteredWorkbooks.map((workbook) => (
					<MenuItem
						key={workbook.id}
						onClick={() => {
							selectWorkbook(workbook);
							handleCloseMenu();
						}}
						sx={{ paddingLeft: '32px' }}
					>
						{workbook.name}
					</MenuItem>
				)),
			);
		}

		if (folder?.permission === 'edit') {
			menuItems.push(
				<MenuItem key="create-report">
					<SigmaButton
						variant="outlined"
						startIcon={<Add />}
						onClick={handleOpenReportAdd}
						sx={{ width: '100%' }}
					>
						Create Report
					</SigmaButton>
				</MenuItem>,
			);
		}

		return (
			<Menu
				anchorEl={anchorEl}
				open={menuId === id}
				onClose={handleCloseMenu}
				sx={{ height: '80%' }}
			>
				{menuItems}
			</Menu>
		);
	};

	return (
		<T4View disablePadding={true} loading={initializing}>
			<AddFolderButton
				open={openFolderAdd}
				setOpen={setOpenFolderAdd}
				parentId={lastOpened ?? ''}
			/>
			<AddReportButton open={openReportAdd} setOpen={setOpenReportAdd} />
			<Grid container spacing={0.5} direction="column" sx={{ height: '100%' }}>
				{showWarning && (
					<Alert
						severity="info"
						onClose={() => {
							setShowWarning(false);
						}}
						sx={{ margin: 2, marginBottom: 0 }}
					>
						<b>Previewing Analytics Studio Beta. </b>This feature is currently
						under development, and unexpected changes may occur.
					</Alert>
				)}
				{folders.length > 0 && (
					<Grid item marginLeft={2}>
						<Grid container marginBottom={0} height={50}>
							<Grid item xs={7}>
								<Breadcrumbs aria-label="breadcrumb" separator="/">
									{path?.map((id) => {
										const folder = folders.find((f) => f.id === id);
										const workbook = workbooks.find((w) => w.id === id);
										return (
											<SigmaButton
												key={id}
												onClick={(event) =>
													handleClickBreadcrumb(
														event,
														folder?.parentId ?? workbook?.folderId ?? '',
													)
												}
												style={{ cursor: 'pointer' }}
											>
												{(folder && folder.name) || (workbook && workbook.name)}{' '}
												<ArrowDropDown />
											</SigmaButton>
										);
									})}
									<SigmaButton
										key={workbook?.id}
										onClick={(event) => {
											if (!loadingNewItem) {
												handleClickBreadcrumb(
													event,
													workbook?.folderId ?? folder?.id ?? '',
												);
											}
										}}
										style={{ cursor: 'pointer' }}
									>
										{workbook && workbook.name}
										{loadingNewItem && 'Loading...'}
										{!workbook && !loadingNewItem && 'Select'}
										<ArrowDropDown />
									</SigmaButton>
								</Breadcrumbs>
							</Grid>
							<Grid item container xs={5} justifyContent="flex-end">
								<SigmaButton
									startIcon={<Cached />}
									onClick={() => generateSigmaUrl()}
								/>
								<DashboardOptions />
							</Grid>
						</Grid>
					</Grid>
				)}

				{menuId &&
					renderMenu(
						menuId,
						folders.find((f) => f.id === menuId)?.parentId ??
							workbook?.folderId ??
							'',
					)}

				{!loadingNewItem && (
					<Grid item sx={{ flex: 2, display: 'flex' }}>
						{workbooks.filter((x) => x.folderId === folder?.id).length !== 0 ? (
							<iframe
								title="Report"
								width="100%"
								style={{ flex: 1, border: 'none' }}
								src={sigmaUrl}
							/>
						) : (
							<CannotDisplay
								headingText="Whoops! No reports here yet..."
								bodyText="Let's change that!"
								imageSrc={NOT_FOUND_MESSAGING.IMAGE}
							/>
						)}
					</Grid>
				)}
			</Grid>
		</T4View>
	);
});
