import { Card, Grid, Typography } from '@mui/material';
import { useReconciliationQuery } from 'features/cash4/_queries/useReconciliationQuery';
import { T4Alert } from 'features/entity4/shared/components/atoms/t4Alert';
import { observer } from 'mobx-react-lite';
import { Reconciliation } from 'modules/clients/customer-api/src/api/cash4';
import moment from 'moment';
import { FC, useMemo } from 'react';
import { ActuallyPrettyGoodDataGridWrapper } from 'shared/components/actuallyPrettyGoodDataGridWrapper';
import { formatCurrency } from 'utilities/currencyUtils';
import { C4AlertBold } from '../_components/c4AlertBold';
import { NotesField } from '../_components/notesField';
import { ProjectedItemsGrid } from '../_components/projectedItemsGrid';
import { ReportedItemsGrid } from '../_components/reportedItemsGrid';
import {
	convertToPercentage,
	getCurrencyCode,
	normalizeReconciliationStatus,
} from '../_hooks/useReconciliationCalculations';
import { SelectedTotal } from '../_components/selectedTotal';

export type ReconciliationViewProps = {
	reconciliation: string | Reconciliation | undefined;
};

export const ReconciliationView: FC<ReconciliationViewProps> = observer(
	({ reconciliation: _reconciliation }) => {
		const { loading, data: reconciliation } =
			useReconciliationQuery(_reconciliation);

		const records = useMemo(() => {
			return reconciliation
				? reconciliation.summary.projectedCount +
						reconciliation.summary.reportedCount
				: 0;
		}, [reconciliation]);

		const variance = useMemo(() => {
			return convertToPercentage(
				reconciliation ? reconciliation.summary.variancePercentage : 1,
			);
		}, [reconciliation]);

		const projectedAmount = useMemo(() => {
			return reconciliation ? reconciliation.summary.projectedAmount : 0;
		}, [reconciliation]);

		const reportedAmount = useMemo(() => {
			return reconciliation ? reconciliation.summary.reportedAmount : 0;
		}, [reconciliation]);

		const unreconciledAmount = useMemo(() => {
			return reconciliation ? reconciliation.summary.unreconciledAmount : 0;
		}, [reconciliation]);

		const currencyCode = useMemo(
			() => getCurrencyCode(reconciliation),
			[reconciliation],
		);

		const projectedItems = useMemo(() => {
			return reconciliation?.projectedItems ?? [];
		}, [reconciliation?.projectedItems]);

		const reportedItems = useMemo(() => {
			return reconciliation?.reportedItems ?? [];
		}, [reconciliation?.reportedItems]);

		return (
			<Grid
				container
				item
				xs={12}
				sx={{
					padding: 0,
					height: '100%',
					gap: 2,
					flexDirection: 'column',
				}}
			>
				<Grid item xs="auto">
					<Card
						sx={(theme) => ({
							backgroundColor: theme.palette.secondary.main,
						})}
					>
						<Grid container sx={{ gap: 2, padding: '1rem' }}>
							<Grid item xs={12}>
								<Typography variant="h3" align="center" sx={{ color: 'white' }}>
									{reconciliation
										? normalizeReconciliationStatus(reconciliation.status)
										: 'Unknown Status'}
								</Typography>
							</Grid>
							<Grid item xs={12}>
								<Typography
									align="center"
									sx={{ color: 'white' }}
								>{`${variance} variance | ${records} associated transactions | ${moment(
									reconciliation?.createdOn ?? undefined,
								)
									.format('DD-MMM-YYYY')
									.toUpperCase()}`}</Typography>
							</Grid>
						</Grid>
					</Card>
				</Grid>
				<Grid
					container
					item
					xs={true}
					sx={{ gap: 2, flexWrap: 'nowrap', minHeight: 460 }}
				>
					<Grid item xs={6}>
						<Grid
							container
							item
							xs={12}
							sx={{
								height: '100%',
								gap: 2,
								flexDirection: 'column',
								flexWrap: 'nowrap',
							}}
						>
							<Grid item xs="auto">
								<Typography variant="h4">Projected</Typography>
							</Grid>
							<Grid item xs={true}>
								<ActuallyPrettyGoodDataGridWrapper>
									<ProjectedItemsGrid
										stonlyId="projected-items-view"
										tableKey="projected-items-view"
										loading={loading}
										projectedItems={projectedItems}
									/>
								</ActuallyPrettyGoodDataGridWrapper>
							</Grid>
							<Grid item xs="auto">
								<SelectedTotal
									amount={projectedAmount}
									currencyCode={currencyCode}
								/>
							</Grid>
						</Grid>
					</Grid>
					<Grid item xs={6}>
						<Grid
							container
							item
							xs={12}
							sx={{
								height: '100%',
								gap: 2,
								flexDirection: 'column',
								flexWrap: 'nowrap',
							}}
						>
							<Grid item xs="auto">
								<Typography variant="h4">Reported</Typography>
							</Grid>
							<Grid item xs={true}>
								<ActuallyPrettyGoodDataGridWrapper>
									<ReportedItemsGrid
										stonlyId="reported-items-view"
										tableKey="reported-items-view"
										loading={loading}
										reportedItems={reportedItems}
									/>
								</ActuallyPrettyGoodDataGridWrapper>
							</Grid>
							<Grid item xs="auto">
								<SelectedTotal
									amount={reportedAmount}
									currencyCode={currencyCode}
								/>
							</Grid>
						</Grid>
					</Grid>
				</Grid>
				<Grid item xs="auto">
					<T4Alert severity="info" fullWidth sx={{ width: '100%' }}>
						{`The selected transactions have an unreconciled amount of `}
						<C4AlertBold>{`${formatCurrency(unreconciledAmount, {
							currency: currencyCode,
						})} ${currencyCode}`}</C4AlertBold>
						{'. The calculated variance is '}
						<C4AlertBold>{`${variance}`}</C4AlertBold>
						{'.'}
					</T4Alert>
				</Grid>
				<Grid item xs="auto">
					<NotesField defaultValue={reconciliation?.notes} disabled />
				</Grid>
			</Grid>
		);
	},
);
