import {
	useUserVisualizationPreference,
	UseUserVisualizationPreferenceProps,
} from 'features/entity4/visualizations/_shared/_hooks/useUserVisualizationPreference';
import { useVisualizationDataQuery } from 'features/entity4/visualizations/_shared/_hooks/useVisualizationDataQuery';
import { LinkData } from 'features/entity4/visualizations/_shared/_templates/link';
import { RegionColors } from 'features/entity4/visualizations/accountMap/models/accountMapTypes';
import { observer } from 'mobx-react-lite';
import {
	LegalEntity,
	Relationship,
	VisualizationPreferencesReq,
} from 'modules/clients/customer-api/src/api/visualizations';
import moment, { Moment } from 'moment';
import {
	createContext,
	FC,
	ReactNode,
	useContext,
	useMemo,
	useState,
} from 'react';
import {
	AsOfDateKey,
	EntityStatusOptionColors,
} from '../../models/orgChartTypes';
import { LegalEntityNodeData } from '../_templates/legalEntityNode';
import { OrgChartLegendData } from '../_templates/orgChartLegend';
import { StandaloneLegalEntitiesData } from '../_templates/standaloneLegalEntities';
//#region Contants

const OrgChartVisualizationKey = 'orgChart';
export const legendKey = 'Legend';
export const standaloneKey = 'Standalone';

const optionIds = {
	displayAuxiliaryNodes: 'displayAuxiliaryNodes',
	displaySecondaryOwnership: 'displaySecondaryOwnership',
	displayLegend: 'displayLegend',
	displayCompact: 'displayCompact',
	displayOverviewMap: 'displayOverviewMap',
	cardOptionsColor: 'cardOptionsColor',
	displayFlag: 'displayFlag',
	information: {
		entityStatus: 'entityStatus',
		entityRegion: 'entityRegion',
		functionalCurrencyCode: 'functionalCurrencyCode',
		erpCode: 'erpCode',
		erpPlatform: 'erpPlatform',
		formOfOrganization: 'formOfOrganization',
		incorporatedDate: 'incorporatedDate',
		dissolutionDate: 'dissolutionDate',
		acquiredCompany: 'acquiredCompany',
		acquisitionDate: 'acquisitionDate',
		stateProvince: 'stateProvince',
		registrationNumber: 'registrationNumber',
		taxIdNumber: 'taxIdNumber',
		taxIdCountry: 'taxIdCountry',
		leiIdentifier: 'leiIdentifier',
	},
};
const startingPreferences: VisualizationPreferencesReq = {
	nodes: [],
	options: [
		// Chart Options
		{
			optionId: optionIds.displayAuxiliaryNodes,
			hide: false,
		},
		{
			optionId: optionIds.displaySecondaryOwnership,
			hide: false,
		},
		{
			optionId: optionIds.displayLegend,
			hide: false,
		},
		{
			optionId: optionIds.displayCompact,
			hide: true,
		},
		{
			optionId: optionIds.displayOverviewMap,
			hide: true,
		},

		// Card Options
		{
			optionId: optionIds.cardOptionsColor,
			hide: false,
			value: 'entityRegion',
		},
		{
			optionId: optionIds.displayFlag,
			hide: false,
		},

		// Information Options
		{
			optionId: optionIds.information.entityStatus,
			hide: false,
		},
		{
			optionId: optionIds.information.entityRegion,
			hide: false,
		},
		{
			optionId: optionIds.information.functionalCurrencyCode,
			hide: false,
		},
		{
			optionId: optionIds.information.erpCode,
			hide: false,
		},
		{
			optionId: optionIds.information.erpPlatform,
			hide: false,
		},
		{
			optionId: optionIds.information.formOfOrganization,
			hide: false,
		},
		{
			optionId: optionIds.information.incorporatedDate,
			hide: false,
		},
		{
			optionId: optionIds.information.dissolutionDate,
			hide: false,
		},
		{
			optionId: optionIds.information.acquiredCompany,
			hide: false,
		},
		{
			optionId: optionIds.information.acquisitionDate,
			hide: false,
		},
		{
			optionId: optionIds.information.stateProvince,
			hide: false,
		},
		{
			optionId: optionIds.information.registrationNumber,
			hide: false,
		},
		{
			optionId: optionIds.information.taxIdNumber,
			hide: false,
		},
		{
			optionId: optionIds.information.taxIdCountry,
			hide: false,
		},
		{
			optionId: optionIds.information.leiIdentifier,
			hide: false,
		},
	],
};

//#endregion

//#region Context

type InitialPreferences = {
	asOfDate: Moment;
	displayStandaloneEntities: boolean;
	displaySecondaryRelationships: boolean;
	displayLegend: boolean;
	displayCompact: boolean;
	displayOverviewMap: boolean;
	displayFlag: boolean;
	colorOption: 'entityRegion' | 'entityStatus' | 'singleColor';
	displayOptions: string[];
};

type OrgChartContextProps = Pick<
	UseUserVisualizationPreferenceProps,
	| 'optionPreferences'
	| 'nodePreferences'
	| 'updatePreferences'
	| 'views'
	| 'createView'
	| 'selectView'
	| 'overwriteView'
	| 'deleteView'
> & {
	isLoading: boolean;
	asOfDate: Moment;
	nodes:
		| (LegalEntityNodeData | OrgChartLegendData | StandaloneLegalEntitiesData)[]
		| undefined;
	links: LinkData[] | undefined;
};

const OrgChartContext = createContext<OrgChartContextProps>(
	{} as OrgChartContextProps,
);

//#endregion

//#region Provider

export type OrgChartProviderProps = {
	children: ReactNode;
};

export const OrgChartProvider: FC = observer(({ children }) => {
	const { loading: loadingData, data } = useVisualizationDataQuery(false, {
		errorMessage: 'Unable to load org chart.',
	});
	const {
		isLoading: loadingPreferences,
		optionPreferences,
		nodePreferences,
		views,
		updatePreferences,
		createView,
		selectView,
		overwriteView,
		deleteView,
	} = useUserVisualizationPreference(
		OrgChartVisualizationKey,
		startingPreferences,
	);

	const [initializing, setInitializing] = useState(true);
	const initialPreferences = useMemo<InitialPreferences | undefined>(() => {
		const shouldDisplay = (optionId: string) => {
			return !(
				optionPreferences.find((x) => x.optionId === optionId)?.hide ?? false
			);
		};

		const colorOption = () => {
			const colorOption = optionPreferences.find(
				(x) => x.optionId === optionIds.cardOptionsColor,
			)?.value;

			switch (colorOption) {
				case 'entityRegion':
					return 'entityRegion';
				case 'entityStatus':
					return 'entityStatus';
				case 'singleColor':
					return 'singleColor';
				default:
					return 'entityRegion';
			}
		};

		const displayOptions = () => {
			const keys = Object.keys(optionIds.information);
			return optionPreferences
				.filter((x) => keys.includes(x.optionId) && shouldDisplay(x.optionId))
				.map((x) => x.optionId);
		};

		if (!loadingPreferences) {
			const asOfDateValue = optionPreferences.find(
				(x) => x.optionId === AsOfDateKey,
			)?.value;
			const asOfDate = asOfDateValue ? moment(asOfDateValue) : moment();

			return {
				asOfDate: asOfDate,
				displayStandaloneEntities: shouldDisplay(
					optionIds.displayAuxiliaryNodes,
				),
				displaySecondaryRelationships: shouldDisplay(
					optionIds.displaySecondaryOwnership,
				),
				displayLegend: shouldDisplay(optionIds.displayLegend),
				displayCompact: shouldDisplay(optionIds.displayCompact),
				displayOverviewMap: shouldDisplay(optionIds.displayOverviewMap),
				displayFlag: shouldDisplay(optionIds.displayFlag),
				colorOption: colorOption(),
				displayOptions: displayOptions(),
			};
		}
	}, [loadingPreferences, optionPreferences]);

	const { nodes, links } = useMemo(() => {
		let result: {
			nodes: (
				| LegalEntityNodeData
				| OrgChartLegendData
				| StandaloneLegalEntitiesData
			)[];
			links: LinkData[];
		} = {
			nodes: [],
			links: [],
		};

		if (!loadingData && initialPreferences) {
			const shouldDisplay = (optionId: string) => {
				return !(
					optionPreferences.find((x) => x.optionId === optionId)?.hide ?? false
				);
			};

			const colorOption = () => {
				const colorOption = optionPreferences.find(
					(x) => x.optionId === optionIds.cardOptionsColor,
				)?.value;

				switch (colorOption) {
					case 'entityRegion':
						return 'entityRegion';
					case 'entityStatus':
						return 'entityStatus';
					case 'singleColor':
						return 'singleColor';
					default:
						return 'entityRegion';
				}
			};

			//#region Links

			const visibleLegalEntityIds =
				data?.legalEntities
					?.filter((x) => {
						const incorperationDate = x.incorporationDate
							? moment(x.incorporationDate)
							: undefined;
						const dissolutionDate = x.dissolutionDate
							? moment(x.dissolutionDate)
							: undefined;

						if (
							(incorperationDate === undefined ||
								initialPreferences.asOfDate.isSameOrAfter(incorperationDate)) &&
							(dissolutionDate === undefined ||
								initialPreferences.asOfDate.isSameOrBefore(dissolutionDate))
						) {
							return true;
						}

						return false;
					})
					?.map((x) => x.id) ?? [];
			const getLinkVisibility = (
				legalEntity: LegalEntity,
				relationship: Relationship,
			) => {
				if (
					!visibleLegalEntityIds.includes(legalEntity.id) ||
					!visibleLegalEntityIds.includes(relationship.objectId) ||
					(relationship.from !== undefined &&
						initialPreferences.asOfDate.isBefore(moment(relationship.from))) ||
					(relationship.to !== undefined &&
						initialPreferences.asOfDate.isAfter(moment(relationship.to)))
				) {
					return false;
				}

				return true;
			};

			const links: LinkData[] = [];

			if (data?.legalEntities) {
				links.push(
					...data.legalEntities.flatMap((legalEntity) =>
						(legalEntity.primaryOwnerOf ?? [])
							.concat(legalEntity.primaryMemberOf ?? [])
							.filter((x) => getLinkVisibility(legalEntity, x))
							.map((relationship) => ({
								key: `${legalEntity.id}-${relationship.objectId}`,
								from: legalEntity.id,
								to: relationship.objectId,
								dateFrom: relationship.from
									? moment(relationship.from)
									: undefined,
								dateTo: relationship.to ? moment(relationship.to) : undefined,
							})),
					),
				);

				links.push(
					...data.legalEntities.flatMap((legalEntity) =>
						(legalEntity.secondaryOwnerOf ?? [])
							.concat(legalEntity.secondaryMemberOf ?? [])
							.filter((x) => getLinkVisibility(legalEntity, x))
							.map((relationship) => ({
								key: `slink-${legalEntity.id}-${relationship.objectId}`,
								category: 'slink',
								from: legalEntity.id,
								to: relationship.objectId,
								dateFrom: relationship.from
									? moment(relationship.from)
									: undefined,
								dateTo: relationship.to ? moment(relationship.to) : undefined,
							})),
					),
				);
			}

			//#endregion

			//#region Nodes

			const getNodeColor = (legalEntity: LegalEntity) => {
				let color = 'white';
				switch (initialPreferences.colorOption) {
					case 'entityRegion': {
						const regionColor = RegionColors.get(
							legalEntity.entityRegion || '',
						);
						if (regionColor) {
							color = regionColor;
						}
						break;
					}
					case 'entityStatus': {
						const statusColor = EntityStatusOptionColors.get(
							legalEntity.entityStatus || '',
						);
						if (statusColor) {
							color = statusColor;
						}
						break;
					}
					case 'singleColor': {
						const userDefinedColor = optionPreferences.find(
							(x) => x.optionId === 'userDefinedColor',
						)?.value;
						if (userDefinedColor) {
							color = userDefinedColor;
						}
						break;
					}
				}

				return color;
			};

			const getNodeVisibility = (legalEntity: LegalEntity) => {
				if (
					(legalEntity.incorporationDate &&
						initialPreferences.asOfDate.isBefore(
							moment(legalEntity.incorporationDate),
						)) ||
					(legalEntity.dissolutionDate &&
						initialPreferences.asOfDate.isAfter(
							moment(legalEntity.dissolutionDate),
						))
				) {
					return false;
				}

				return true;
			};

			const getNodeGroup = (legalEntity: LegalEntity) => {
				return links
					.filter((x) => x.category !== 'slink')
					.some((x) => x.from === legalEntity.id || x.to === legalEntity.id)
					? undefined
					: 'Standalone';
			};

			const validKeys = Object.keys(optionIds.information);
			const visibleFields = optionPreferences
				.filter(
					(x) => validKeys.includes(x.optionId) && shouldDisplay(x.optionId),
				)
				.map((x) => x.optionId);

			const legalEntityNodes: LegalEntityNodeData[] = [];

			legalEntityNodes.push(
				...(data?.legalEntities
					?.filter((x) => getNodeVisibility(x))
					?.map((legalEntity) => {
						return {
							key: legalEntity.id,

							entityId: legalEntity.id,
							entityCode: legalEntity.entityCode,
							anglicizedLegalName: legalEntity.anglicizedLegalName,
							country: legalEntity.country,
							entityStatus: legalEntity.entityStatus,
							entityRegion: legalEntity.entityRegion,
							functionalCurrencyCode: legalEntity.functionalCurrencyCode,
							incorporatedDate: legalEntity.incorporationDate,
							erpCode: legalEntity.erpCode,
							erpPlatform: legalEntity.erpPlatform,
							acquiredCompany: legalEntity.acquiredCompany,
							acquisitionDate: legalEntity.acquisitionDate,
							dissolutionDate: legalEntity.dissolutionDate,
							leiIdentifier: legalEntity.leiIdentifier,
							stateProvince: legalEntity.homeRegistration?.stateProvince,
							registrationNumber: legalEntity.homeRegistration?.number,
							taxIdNumber: legalEntity.federalTaxId?.number,
							taxIdCountry: legalEntity.federalTaxId?.country,
							formOfOrganization: legalEntity.formOfOrganization,

							isFlagVisible: initialPreferences?.displayFlag ?? true,
							currentColor: getNodeColor(legalEntity),
							group: getNodeGroup(legalEntity),
							isVisible: getNodeVisibility(legalEntity),
							visibleFields: visibleFields,
						};
					}) ?? []),
			);

			//#endregion

			const visibleLegalEntityNodes = legalEntityNodes.filter(
				(x) => x.isVisible,
			);
			const standaloneNode: StandaloneLegalEntitiesData = {
				key: standaloneKey,
				isGroup: true,
				isOrphanGroupVisible: initialPreferences.displayStandaloneEntities,
			};

			const entityRegions = visibleLegalEntityNodes.map((x) => x.entityRegion!);
			const distinctEntityRegions = [...new Set(entityRegions)].filter(Boolean);
			const entityStatuses = visibleLegalEntityNodes.map(
				(node) => node.entityStatus!,
			);
			const distinctEntityStatuses = [...new Set(entityStatuses)].filter(
				Boolean,
			);

			const colorByOption = colorOption();
			const legendNode: OrgChartLegendData = {
				key: legendKey,
				category: legendKey,
				isGroup: true,
				entityRegions: distinctEntityRegions,
				entityStatuses: distinctEntityStatuses,
				isSecondaryOwnershipVisible: shouldDisplay(
					optionIds.displaySecondaryOwnership,
				),
				isEntityRegionVisible: colorByOption === 'entityRegion',
				isEntityStatusVisible: colorByOption === 'entityStatus',
				isLegendVisible: shouldDisplay(optionIds.displayLegend),
			};

			setInitializing(false);
			result = {
				nodes: [...legalEntityNodes, standaloneNode, legendNode],
				links: links,
			};
		}

		return result;
	}, [data?.legalEntities, initialPreferences, loadingData, optionPreferences]);

	const asOfDate = useMemo(() => {
		const asOfDateValue = optionPreferences.find(
			(x) => x.optionId === AsOfDateKey,
		)?.value;

		return asOfDateValue ? moment(asOfDateValue) : moment();
	}, [optionPreferences]);

	return (
		<OrgChartContext.Provider
			value={{
				isLoading: loadingData || loadingPreferences || initializing,
				nodes: nodes,
				links: links,
				asOfDate: asOfDate,
				optionPreferences: optionPreferences,
				nodePreferences: nodePreferences,
				views: views,
				updatePreferences: updatePreferences,
				createView: createView,
				selectView: selectView,
				overwriteView: overwriteView,
				deleteView: deleteView,
			}}
		>
			{children}
		</OrgChartContext.Provider>
	);
});

//#endregion

//#region Hook

export type UseOrgChartProps = OrgChartContextProps;

export function useOrgChart(): UseOrgChartProps {
	return useContext(OrgChartContext);
}

//#endregion
