import {
	Binding,
	GraphObject,
	Margin,
	Panel,
	Picture,
	Shape,
	Size,
	Spot,
	Stretch,
	TextBlock,
	TextOverflow,
	Wrap,
} from 'gojs';
import { nodeContainer } from '../../_shared/_templates/node';

export function slimLegalEntityNode() {
	const width = 350;
	const flagHeight = 35;
	const flagWidth = 53;

	return nodeContainer(width, [
		new Panel(Panel.Vertical, {
			width: width - 1,
			margin: new Margin(0, 0, 0.5, 0),
		}).add(
			new Panel(Panel.Auto, {
				stretch: Stretch.Horizontal,
			}).add(
				new Shape('RoundedTopRectangle', {
					parameter1: 6,
					height: 30,
					fill: 'rgba(0, 0, 0, 0.1)',
					alignment: Spot.Top,
					strokeWidth: 0,
					width: width - 1,
				}),
				new TextBlock('', {
					margin: new Margin(8, 8, 8, 8),
					alignment: Spot.Left,
					maxSize: new Size(width, NaN),
					wrap: Wrap.None,
					overflow: TextOverflow.Ellipsis,

					name: 'entityURL',
					cursor: 'pointer',
					click: (_, obj) => {
						window.open('entities/' + obj.part?.data.entityId + '/information');
					},
					mouseEnter: (_, obj) => {
						(obj.part?.findObject('entityURL') as TextBlock).isUnderline = true;
					},
					mouseLeave: (_, obj) => {
						(obj.part?.findObject('entityURL') as TextBlock).isUnderline =
							false;
					},
				}).bind(new Binding('text', '', (data) => data.entityErpCode || '-')),
			),
			new Panel(Panel.Auto, {
				stretch: Stretch.Horizontal,
			}).add(
				new Shape('RoundedBottomRectangle', {
					parameter1: 6,
					fill: '#F4F4F4',
					strokeWidth: 0,
					width: width - 1,
				}),

				new Panel('Table', {
					stretch: Stretch.Horizontal,
				}).add(
					new Picture({
						name: 'flagv',
						margin: new Margin(8, 8, 0, 8),
						row: 0,
						column: 0,
						height: flagHeight,
						width: flagWidth,
					})
						.bind(
							new Binding('source', '', (data) => {
								return (
									window.location.origin + '/svg/' + data.entityCountry + '.svg'
								);
							}),
						)
						.bind(new Binding('visible', 'isFlagVisible')),
					new TextBlock({
						margin: new Margin(8, 8, 4, 8),
						alignment: Spot.Left,
						row: 0,
						column: 1,
						width: width - flagWidth,
						wrap: Wrap.None,
						overflow: TextOverflow.Ellipsis,
					}).bind(new Binding('text', 'entityName')),
					GraphObject.make('EntityViewTreeExpanderButton', {
						alignment: Spot.Center,
						row: 1,
						scale: 2,
						column: 0,
						columnSpan: 2,
					}),
				),
			),
		),
	]);
}
