import { AxiosInstance, AxiosResponse } from 'axios';
import { T4DataResponse, T4DataResponse2 } from 'modules/clients/types';

//#region Read Models

export type Party = {
	id: string;
	type: string;
	name: string;
	accounts?: PartyAccount[];
};

export type PartyAccount = {
	id: string;
	name: string;
	number?: string;
	currencyCode?: string;
};

export type ReconciliationSummary = {
	reconciliationRecordId: string;
	reconciliationStatus: string;
	variancePercentage: number;
	unreconciledAmount: number;
	reconciledAmount: number;
	projectedAmount: number;
	projectedCount: number;
	reportedAmount: number;
	reportedCount: number;
};

export type Reconciliation = {
	id: string;
	status: string;
	notes?: string;
	createdOn: string;
	createdBy: string;
	projectedItems: ProjectedItem[];
	reportedItems: ReportedItem[];
	summary: ReconciliationSummary;
};

export type ReportedItem = {
	id: string;
	date: string;
	amount: number;
	currencyCode?: string;
	baiCode?: string;
	bankReference?: string;
	customerReference?: string;
	checkNumber?: string;
	detail?: string;
	note?: string;
	fiTransactionId?: string;
	reconciliationId?: string;
	reconciliationStatus: string;
	createdOn: string;
	categorization?: CategorizationDetail;
	c4Account: AccountInformation;
	e4Account?: AccountInformation;
};

export type ProjectedItem = {
	id: string;
	primaryParty: PartyInformation;
	secondaryParty: PartyInformation;
	date: string;
	amount: number;
	currencyCode: string;
	checkNumber?: string;
	label?: string;
	description?: string;
	reconciliationId?: string;
	reconciliationStatus: string;
	categorization?: CategorizationDetail;
	createdBy: string;
	createdOn: string;
	updatedBy?: string;
	updatedOn?: string;
	deletedBy?: string;
	deletedOn?: string;
	flowDirection: string;
	isForecastModelExcluded: boolean;
	reconciliationSummary?: ReconciliationSummary;
};

export type PartyInformation = {
	type: string;
	object?: ObjectInformation;
	account?: AccountInformation;
};

export type BankInformation = {
	code: string;
	name?: string;
};

export type AccountInformation = {
	id: string;
	name: string;
	number: string;
	bank?: BankInformation;
};

export type ObjectInformation = {
	id: string;
	name: string;
};

export type CategorizationDetail = {
	id: string;
	name: string;
	isManual: boolean;
	class: CashFlowDetail;
	type: CashFlowDetail;
	subtype?: CashFlowDetail;
	glCode?: GeneralLedgerDetail;
};

export type CashFlowDetail = {
	id: string;
	code: string;
	name: string;
};

export type GeneralLedgerDetail = {
	id: string;
	code: string;
};

export type QueuedAccountIntegrations = {
	count: number;
};

export type AccountSyncEvent = {
	lastUpdatedDate: string;
};

//#endregion

//#region Write Models

export type ReconciliationReq = {
	note?: string;
	projectedTransactions: string[];
	reportedTransactions: string[];
};

export type ProjectedItemReq = {
	amount: number;
	currencyCode: string;
	expectedValueDate: string;
	label?: string;
	description?: string;
	checkNumber?: string;
	primaryParty: PartyReq;
	secondaryParty?: PartyReq;
	categorization?: CategorizationReq;
	flowDirection: string;
	isForecastModelExcluded: boolean;
};

export type PartyReq = {
	partyType?: string;
	entityId?: string;
	accountId?: string;
};

export type CategorizationReq = {
	classId: string;
	typeId: string;
	subtypeId?: string;
	glCode?: GlCodeReq;
};

export type GlCodeReq = {
	id?: string;
	code?: string;
};

//#endregion

//#region Request Models

export type GetProjectedItemsRequest = {
	startDate?: string;
	endDate?: string;
	reconciliationStatuses?: string[];
	legalEntityGroupIds?: string[];
};

export type GetReportedItemsRequest = {
	startDate: string;
	endDate: string;
	reconciliationStatuses?: string[];
};

//#endregion

export type Cash4Endpoints = {
	parties: () => Promise<AxiosResponse<T4DataResponse2<Party[]>>>;
	projected: (
		params?: GetProjectedItemsRequest,
	) => Promise<AxiosResponse<T4DataResponse2<ProjectedItem[]>>>;
	singleProjected: (
		id: string,
	) => Promise<AxiosResponse<T4DataResponse<ProjectedItem>>>;
	reported: (
		params: GetReportedItemsRequest,
	) => Promise<AxiosResponse<T4DataResponse2<ReportedItem[]>>>;
	reconciliations: () => Promise<
		AxiosResponse<T4DataResponse2<Reconciliation[]>>
	>;
	reconciliation: (
		id: string,
	) => Promise<AxiosResponse<T4DataResponse<Reconciliation>>>;
	updateReconciliation: (
		id: string,
		req: ReconciliationReq,
	) => Promise<AxiosResponse<T4DataResponse2<string>>>;
	deleteReconciliation: (
		id: string,
	) => Promise<AxiosResponse<T4DataResponse2<string>>>;
	createProjected: (
		data: ProjectedItemReq,
	) => Promise<AxiosResponse<T4DataResponse<string>, ProjectedItemReq>>;
	updateProjected: (
		id: string,
		data: ProjectedItemReq,
	) => Promise<AxiosResponse<T4DataResponse<string>, ProjectedItemReq>>;
	updateCurrentDay: () => Promise<
		AxiosResponse<T4DataResponse2<QueuedAccountIntegrations>>
	>;
	recentSyncEvent: () => Promise<
		AxiosResponse<T4DataResponse2<AccountSyncEvent>>
	>;
};

const apiCash4Path = 'api/cash4';
const apiV1Cash4Path = 'api/v1.0/cash4';
const reconciliationRecordsPath = apiV1Cash4Path + '/reconciliationRecords';
const projectedTransactionsPath = apiV1Cash4Path + '/projectedTransactions';
const projectedPath = apiCash4Path + '/projected';
const reportedPath = apiCash4Path + '/reported';
const partiesPath = apiCash4Path + '/parties';
const reconciliationsPath = apiCash4Path + '/reconciliations';
const accountIntegrationsPath = apiV1Cash4Path + '/accountIntegrations';

export function cash4(axiosInstance: AxiosInstance): Cash4Endpoints {
	return {
		parties: async () =>
			await axiosInstance.get<T4DataResponse2<Party[]>>(partiesPath),
		projected: async (params) =>
			await axiosInstance.get<T4DataResponse2<ProjectedItem[]>>(projectedPath, {
				params: params,
				paramsSerializer: {
					indexes: null,
				},
			}),
		singleProjected: async (id) =>
			await axiosInstance.get<T4DataResponse<ProjectedItem>>(
				`${projectedPath}/${id}`,
			),
		reported: async (params) =>
			await axiosInstance.get<T4DataResponse2<ReportedItem[]>>(reportedPath, {
				params: params,
				paramsSerializer: {
					indexes: null,
				},
			}),
		reconciliations: async () =>
			await axiosInstance.get<T4DataResponse2<Reconciliation[]>>(
				reconciliationsPath,
			),
		reconciliation: async (id) =>
			await axiosInstance.get<T4DataResponse<Reconciliation>>(
				`${reconciliationsPath}/${id}`,
			),
		updateReconciliation: async (id, req) =>
			await axiosInstance.put<T4DataResponse2<string>>(
				`${reconciliationRecordsPath}/${id}`,
				req,
			),
		deleteReconciliation: async (reconciliationRecordId: string) =>
			await axiosInstance.delete<T4DataResponse2<string>>(
				`${reconciliationRecordsPath}/${reconciliationRecordId}`,
			),
		createProjected: async (data) =>
			await axiosInstance.post<
				T4DataResponse<string>,
				AxiosResponse<T4DataResponse<string>, ProjectedItemReq>
			>(projectedTransactionsPath, data),
		updateProjected: async (id, data) =>
			await axiosInstance.put<
				string,
				AxiosResponse<T4DataResponse<string>, ProjectedItemReq>
			>(`${projectedTransactionsPath}/${id}`, data),
		updateCurrentDay: async () =>
			await axiosInstance.post<T4DataResponse2<QueuedAccountIntegrations>>(
				`${accountIntegrationsPath}/currentDay/refresh`,
			),
		recentSyncEvent: async () =>
			await axiosInstance.get<T4DataResponse2<AccountSyncEvent>>(
				`${accountIntegrationsPath}/accountSyncEvents/recent?reportType=current`,
			),
	};
}
