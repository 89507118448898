import { ExpandMore, Share } from '@mui/icons-material';
import {
	Box,
	Button,
	Collapse,
	Divider,
	Grid,
	Typography,
} from '@mui/material';
import { T4TextFieldV2 } from 'features/entity4/shared/components/atoms/t4TextField';
import { useSnackbar } from 'notistack';
import { FC, useCallback, useEffect, useState } from 'react';
import { CopyToClipboardIconButton } from 'shared/components/copyToClipboardIconButton';
import { T4DrawerBase } from 'shared/components/drawer/drawerBase';
import { DrawerCancelButton } from 'shared/components/drawer/drawerButtons';
import { useGetPaymentTemplate } from '../hooks/usePaymentTemplates';
import { PaymentPartyInformation } from '../paymentPartyInformationBox';
import { getPaymentTemplateStatusText } from './utilities';
import { PaymentTemplateStatusTypes } from 'modules/clients/apiGateway/payments4/paymentTemplates';
import { T4Alert } from 'features/entity4/shared/components/atoms/t4Alert';

interface TemplateDetailsDrawerProps {
	templateId: string | null;
	version: number | null;
	onClose: () => void;
}

export const TemplateDetailsDrawer: FC<TemplateDetailsDrawerProps> = ({
	templateId,
	version,
	onClose,
}) => {
	const { enqueueSnackbar } = useSnackbar();

	const {
		isLoading,
		isFetching,
		data: template,
		error,
	} = useGetPaymentTemplate(templateId, version);

	const [collapseOpen, setCollapseOpen] = useState<boolean>(false);
	const closeDrawer = useCallback(() => {
		onClose();
		setCollapseOpen(false);
	}, [onClose]);

	useEffect(() => {
		if (!isLoading && error?.message) {
			if (!template) closeDrawer();
			enqueueSnackbar(error.message, {
				variant: 'error',
			});
		}
	}, [isLoading, template, error, closeDrawer, enqueueSnackbar]);

	return (
		<T4DrawerBase
			title="Template Details"
			open={!!templateId}
			initializing={isLoading || isFetching}
			onClose={closeDrawer}
			utilityActions={[
				<CopyToClipboardIconButton
					valueToCopy={window.location.href}
					initialTooltipText="Copy Link"
				>
					<Share />
				</CopyToClipboardIconButton>,
			]}
			actions={[<DrawerCancelButton label="Close" onCancel={closeDrawer} />]}
			disableNavigationCollapse
		>
			<Grid container sx={{ gap: 2 }}>
				<Grid container item xs={12} sx={{ gap: 1 }}>
					<Grid container item xs={12}>
						<Grid item xs={6}>
							<Typography variant="body1" sx={{ fontWeight: 500 }}>
								Current Status
							</Typography>
						</Grid>
						<Grid item xs={6} sx={{ textAlign: 'right' }}>
							<Typography variant="body1">
								{getPaymentTemplateStatusText(template?.currentStatus ?? null)}
							</Typography>
						</Grid>
					</Grid>
					<Grid container item xs={12}>
						<Grid item xs={6}>
							<Typography variant="body1" sx={{ fontWeight: 500 }}>
								Template Name
							</Typography>
						</Grid>
						<Grid item xs={6} sx={{ textAlign: 'right' }}>
							<Typography variant="body1">{template?.name}</Typography>
						</Grid>
					</Grid>
					<Grid container item xs={12}>
						<Grid item xs={6}>
							<Typography variant="body1" sx={{ fontWeight: 500 }}>
								Payment Type
							</Typography>
						</Grid>
						<Grid item xs={6} sx={{ textAlign: 'right' }}>
							<Typography variant="body1">{template?.paymentType}</Typography>
						</Grid>
					</Grid>
					<Grid container item xs={12}>
						<Grid item xs={6}>
							<Typography variant="body1" sx={{ fontWeight: 500 }}>
								Payment Currency
							</Typography>
						</Grid>
						<Grid item xs={6} sx={{ textAlign: 'right' }}>
							<Typography variant="body1">{template?.currencyCode}</Typography>
						</Grid>
					</Grid>
					{template?.currentStatus ===
						PaymentTemplateStatusTypes[PaymentTemplateStatusTypes.Rejected] && (
						<Grid container item xs={12}>
							<T4Alert
								severity="error"
								sx={{
									'&.MuiPaper-root': {
										height: '100%',
										width: '100%!important',
									},
								}}
							>
								<Box sx={{ display: 'flex', flexDirection: 'column', gap: 1 }}>
									<Typography variant="body2" fontWeight={500}>
										Rejection Reason
									</Typography>
									<Typography variant="caption">
										{
											template.statusHistory.find(
												(x) =>
													x.templateVersion === template.templateVersion &&
													x.paymentTemplateStatusType ===
														PaymentTemplateStatusTypes[
															PaymentTemplateStatusTypes.Rejected
														],
											)?.reason
										}
									</Typography>
								</Box>
							</T4Alert>
						</Grid>
					)}
				</Grid>

				<Grid item xs={12}>
					<Divider />
				</Grid>

				<Grid item xs={12} sx={{ display: 'flex', justifyContent: 'flex-end' }}>
					<Button
						onClick={() => setCollapseOpen(!collapseOpen)}
						endIcon={
							<ExpandMore
								sx={{
									transition: 'rotate .25s',
									rotate: collapseOpen ? '180deg' : '0deg',
								}}
							/>
						}
						variant="text"
						color="secondary"
					>
						{collapseOpen ? 'Collapse Details' : 'Expand Details'}
					</Button>
				</Grid>

				<Collapse in={collapseOpen} unmountOnExit>
					<Grid container sx={{ gap: 2 }}>
						<Grid container item xs={12} spacing={1}>
							<PaymentPartyInformation
								party={template?.initiator ?? null}
								partyType="Initiator"
							/>
						</Grid>
						<Grid item xs={12}>
							<Divider />
						</Grid>
						<Grid container item xs={12} spacing={1}>
							<PaymentPartyInformation
								party={template?.payee ?? null}
								partyType="Payee"
							/>
						</Grid>
					</Grid>
				</Collapse>

				<Grid item xs={12}>
					<T4TextFieldV2
						label="Payment Information"
						value={template?.referenceData ?? ''}
						minRows={4}
						maxRows={4}
						multiline
						InputProps={{ readOnly: true }}
					/>
				</Grid>
			</Grid>
		</T4DrawerBase>
	);
};
